import getDashedTitle from '@/utilities/getDashedTitle';
import EventBus from '@/utilities/eventBus';
import cardStatuses from '@/mixins/cardStatuses';
import { ResourceType } from '@/types/resources';
import { getCardStatus } from '@/utilities/defaultCardStatuses';
import cardStatusType from '@/types/cardStatusType';

export default {
  mixins: [cardStatuses],
  data() {
    return {
      currentlyViewed: () => ({}),
      recentlyViewedGenItemTimeout: null,
    };
  },
  computed: {
    displayedCards() {
      return this.$store.getters.getDisplayedCards;
    },

    gettersAndIconsMap() {
      return {
        [this.$constants.routeNames.view]: {
          getter: () => this.$store.getters.getSelectedView,
          icon: 'view',
        },
        [this.$constants.routeNames.page]: {
          getter: () => this.$store.getters.getPage,
          icon: (page) => page?.icon?.src || 'page',
          iconType: (page) => page?.icon?.type || 'icon',
        },
        [this.$constants.routeNames.board]: {
          getter: () => this.$store.getters.currentBoard,
          icon: 'board',
        },
        [this.$constants.routeNames.space]: {
          getter: () => this.$store.getters.getCurrentProject,
          icon: (project) => project?.icon?.src || '',
          iconType: (project) => project.icon?.type || '',
        },
        [this.$constants.routeNames.boardCard]: {
          getter: (id) => this.$store.getters.getCardById(id),
          icon: (card) => this.cardStatusIcon(card),
        },
        [this.$constants.epicRoute.name]: {
          getter: (id) => this.$store.getters.getCardById(id),
          icon: (epic) => this.cardStatusIcon(epic),
          additionalIcon: (epic) => epic?.icon || { src: this.$constants.epicDefaultIcon },
        },
        [ResourceType.Member]: {
          getter: (id) => this.$store.getters.getMemberById(id),
        },
      };
    },

    recentlyViewedLS() {
      const currentTeamIdLS = this.teamId ?? localStorage.getItem('currentTeamId');
      return currentTeamIdLS
        ? JSON.parse(localStorage.getItem(`recentlyViewed:${currentTeamIdLS}`) || '[]')
        : [];
    },

    teamId() {
      return this.$store.getters.getTeamID;
    },

    teamSlug() {
      return this.$store.getters.getTeamSlug;
    },

    pages() {
      return this.$store.getters.getPages;
    },

    cards() {
      return this.$store.getters.getCards;
    },

    boards() {
      return this.$store.getters.getFlatBoards;
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(newVal) {
        this.recentlyViewedGenItemTimeout = setTimeout(() => {
          const id = newVal.params?.userId;
          this.generateItemForRecentlyViewedDropdown(newVal.name, newVal.path, id);
        }, 250);
      },
    },
    displayedCards(newVal, oldVal) {
      // check if any elements from oldVal are missing in newVal
      // these elements are newly opened cards
      const diff = newVal.filter((nv) => !oldVal.some((ov) => ov?.id === nv?.id));

      for (const newCard of diff) {
        const { title } = this.$store.getters.getCardById(newCard.id);
        const isEpic = newCard.resource_type === ResourceType.Epic;
        const path = isEpic ? this.$constants.epicRoute.path : 'card';
        const routeName = isEpic
          ? this.$constants.epicRoute.name
          : this.$constants.routeNames.boardCard;
        this.generateItemForRecentlyViewedDropdown(
          routeName,
          `/${this.teamSlug}/${path}-${newCard.id}-${getDashedTitle(title)}/`,
          newCard.id
        );
        return;
      }

      this.addItemToRecentlyViewedDropdown();
    },
    teamId: {
      handler(newVal) {
        if (!newVal) return;
        this.setRecentlyViewed(this.recentlyViewedLS);
      },
      immediate: true,
    },
  },
  mounted() {
    EventBus.$on('addQuickCardToRecentlyViewed', this.saveQuickCardCreatedToRecentlyViewed);
    EventBus.$on('addQuickPageToRecentlyViewed', this.saveQuickPageCreatedToRecentlyViewed);
  },
  beforeUnmount() {
    EventBus.$off('addQuickCardToRecentlyViewed', this.saveQuickCardCreatedToRecentlyViewed);
    EventBus.$off('addQuickPageToRecentlyViewed', this.saveQuickPageCreatedToRecentlyViewed);

    this.setRecentlyViewed([]);
    this.generateItemForRecentlyViewedDropdown(this.$route.name, this.$route.path);
    clearTimeout(this.recentlyViewedGenItemTimeout);
  },
  methods: {
    setRecentlyViewed(payload) {
      this.$store.dispatch('setElectronRecentlyViewed', payload);
    },

    cardStatusIcon(card) {
      const currentCardList = this.$store.getters.lists[card.list_id] || null;
      const cardStatus = currentCardList || this.cardStatuses[card.status] || {};
      const isEpic = card.type === ResourceType.Epic;

      return (
        (getCardStatus(cardStatus?.behavior, isEpic) || {}).icon ||
        cardStatus.icon ||
        getCardStatus(cardStatusType.Backlog, isEpic).icon ||
        ''
      );
    },

    isInvalidItemForRecentlyViewed(item, type) {
      if (type === ResourceType.Member) return false;

      const { id, title, name } = item;
      return !id || (!title && !name && type !== this.$constants.routeNames.page);
    },

    getLabel(item, type) {
      const memberLabel = item.display_name || `${item.first_name} ${item.last_name}`;
      return (
        item.title ||
        item.name ||
        (type === ResourceType.Member && memberLabel) ||
        (type === this.$constants.routeNames.page ? this.translate('pageTitlePlaceholder') : '')
      );
    },

    generateItemForRecentlyViewedDropdown(type, path, resourceId) {
      // adds the previously stored item to the dropdown
      this.addItemToRecentlyViewedDropdown(this.teamId);

      const config = this.gettersAndIconsMap[type];
      if (!config) return;

      const item = config.getter(resourceId);

      if (this.isInvalidItemForRecentlyViewed(item, type)) return;

      const { id, project_id } = item;
      const label = this.getLabel(item, type);

      const configIconType = typeof config.iconType === 'function' ? config.iconType(item) : '';

      let icon;
      if ([this.$constants.epicRoute.name, this.$constants.routeNames.boardCard].includes(type)) {
        icon = {
          name: config.icon(item),
          color: item.list_color,
        };
      } else {
        icon = {
          name: typeof config.icon === 'function' ? config.icon(item) : config.icon,
          color: item.icon?.color || '',
          type: item.icon?.type ? item.icon.type : configIconType,
        };
      }

      let additionalIcon;
      if (type === this.$constants.epicRoute.name) {
        additionalIcon = config.additionalIcon(item);
      }

      const payload = {
        label,
        icon,
        id,
        type,
        path,
        ...(project_id && { project_id }),

        // special case icon, currently only used for epics
        ...(additionalIcon && { additionalIcon }),

        // special case for members
        ...(type === ResourceType.Member && {
          role: item.role,
          first_name: item.first_name,
          last_name: item.last_name,
        }),
      };

      // sets the current item to be added later on
      this.currentlyViewed = payload;
    },

    addItemToRecentlyViewedDropdown(teamId = this.teamId) {
      if (Object.keys(this.currentlyViewed).length) {
        const { currentlyViewed } = this;
        this.addToElectronRecentlyViewed({ currentlyViewed, teamId });
        this.currentlyViewed = {};
      }
    },

    addToElectronRecentlyViewed(payload) {
      if (this.isResourceDeleted(payload.currentlyViewed)) return;
      this.$store.dispatch('addToElectronRecentlyViewed', payload);
    },

    isResourceDeleted(currentlyViewed) {
      if (currentlyViewed.type === this.$constants.routeNames.page) {
        return !this.pages[currentlyViewed.id];
      } else if (currentlyViewed.type === this.$constants.routeNames.boardCard) {
        return !this.cards[currentlyViewed.id];
      } else if (currentlyViewed.type === this.$constants.routeNames.board) {
        return this.boards[`${currentlyViewed.id}_notFound`];
      }
      return false;
    },

    saveQuickCardCreatedToRecentlyViewed(card) {
      const path = `/${this.teamSlug}/card-${card.id}-${getDashedTitle(card.title)}`;
      const icon = {
        name: this.cardStatusIcon(card),
        color: card.list_color || '',
      };
      const payload = {
        label: card.title,
        path,
        icon,
        type: this.$constants.routeNames.boardCard,
        id: card.id,
      };

      this.addToElectronRecentlyViewed({
        currentlyViewed: payload,
        teamId: this.teamId,
      });
    },

    saveQuickPageCreatedToRecentlyViewed(page) {
      const label = page.title || this.translate('pageTitlePlaceholder');
      const path = `/${this.teamSlug}/page-${page.id}-${getDashedTitle(label)}`;
      const icon = {
        name: 'page',
        color: '',
        type: '',
      };
      const payload = {
        label,
        path,
        icon,
        type: this.$constants.routeNames.page,
        id: page.id,
      };

      this.addToElectronRecentlyViewed({
        currentlyViewed: payload,
        teamId: this.teamId,
      });
    },
  },
};
