<template>
  <div
    class="electron-extras py-1 flex justify-end items-center"
    :class="{ 'electron-drag': (!isAnyCardDisplayed && !showPeekSidebar) || isSettings }"
  >
    <div
      class="flex just items-center h-8 mr-2 ml-2 gap-2 electron-no-drag"
      :class="
        isWindowsOS || (!isSettings && showPeekSidebar) ? 'justify-start w-full' : 'justify-end'
      "
    >
      <div
        v-for="(direction, index) in ['left', 'right']"
        :key="index"
        class="group flex items-center justify-center p-0.5 cursor-pointer h-6 w-6 rounded"
        :class="{
          'pointer-events-none': isDisabled(direction),
          'cursor-pointer hover:bg-hover-onHover':
            !isDisabled(direction) && !isAnyCardDisplayedInModalLayout,
          'cursor-pointer hover:bg-hover-electron':
            isAnyCardDisplayedInModalLayout && !isDisabled(direction),
        }"
        @click="throttledNavigateButtonClick(direction)"
      >
        <em
          class="custom-icons bg-icon-default"
          :class="{
            'bg-inactive-icon': isDisabled(direction),
            'group-hover:bg-icon-hover': !isDisabled(direction) && !isAnyCardDisplayedInModalLayout,
            'bg-icon-electron': isAnyCardDisplayedInModalLayout && !isDisabled(direction),
          }"
          :style="getCustomIconStyle(iconPath(`chevron-${direction}`))"
        />
      </div>
      <v-popover
        interactive
        tag="div"
        content-tag="div"
        :trigger="'click'"
        :role="'poper'"
        placement="bottom-end"
        class="flex"
        :class="{ 'ml-auto': showPeekSidebar || isWindowsOS }"
      >
        <div
          class="group flex items-center justify-center cursor-pointer p-0.5 h-6 w-6 rounded"
          :class="{
            'hover:bg-hover-onHover': !isAnyCardDisplayedInModalLayout,
            'hover:bg-hover-electron': isAnyCardDisplayedInModalLayout,
          }"
        >
          <em
            class="custom-icons bg-icon-default"
            :class="{
              'group-hover:bg-icon-hover': !isAnyCardDisplayedInModalLayout,
              'bg-icon-electron': isAnyCardDisplayedInModalLayout,
            }"
            :style="getCustomIconStyle(iconPath('clock'))"
          />
        </div>
        <template #content="{ state, hide }">
          <ListDropdown
            v-if="state.isVisible"
            :width="240"
            :items="recentlyViewedDropdownItems"
            :title="translate('electronRecentlyViewed')"
            @click="handleRecentlyViewedDropdownItemClick($event, hide)"
            @closeEscape="hide"
            @hoverDropdownItem="handleRecentlyViewedDropdownItemHover"
          />
        </template>
      </v-popover>
    </div>
  </div>
</template>
<script>
import customIconsMixin from '@/mixins/customIconsMixin';
import ListDropdown from '@/components/widgets/ListDropdown.vue';
import isCardMixin from '@/mixins/isCardMixin';
import cardStatuses from '@/mixins/cardStatuses';
import useSetAndOpenProject from '@/utilities/composables/useSetAndOpenProject';
import viewResultsMixin from '@/mixins/viewResultsMixin';
import throttle from 'lodash/throttle';
import getDashedTitle from '@/utilities/getDashedTitle';
import { ResourceType } from '@/types/resources';

export default {
  name: 'ElectronExtras',
  components: {
    ListDropdown,
  },
  mixins: [customIconsMixin, isCardMixin, cardStatuses, viewResultsMixin],
  setup() {
    const { setAndOpenProject } = useSetAndOpenProject();
    return { setAndOpenProject };
  },
  data() {
    return {
      goNextClicked: false,
      goPreviousClicked: false,
    };
  },
  computed: {
    isAnyCardDisplayed() {
      return this.$store.getters.getIsAnyCardDisplayed;
    },
    isAnyCardDisplayedInDockedLayout() {
      return this.$store.getters.getIsAnyCardDisplayedInLayout(this.$constants.docked);
    },
    isAnyCardDisplayedInModalLayout() {
      return this.$store.getters.getIsAnyCardDisplayedInLayout(this.$constants.modal);
    },
    historyIndex() {
      return this.$store.getters.getElectronBrowseHistoryIndex;
    },
    recentlyViewedDropdownItems() {
      return this.$store.getters.getElectronRecentlyViewed.map(
        ({ label, path, id, icon, type, additionalIcon }) => {
          const hasEmojiAsIcon = icon.type === 'emoji';
          const hasImageAsPageIcon = type === 'page' && icon.type === 'image';
          return {
            label,
            path,
            id,
            type,
            iconType: icon.type,
            hover: true,
            ...(!hasEmojiAsIcon && { customIconName: this.iconPath(icon.name) }),
            ...(!hasEmojiAsIcon && { customIconColor: icon.color }),
            ...(hasEmojiAsIcon && { emojiIcon: icon.name }),
            ...(hasImageAsPageIcon && { imageSrc: icon.name }),
            additionalIcon,
          };
        }
      );
    },
    browseHistory() {
      return this.$store.getters.getElectronBrowseHistory;
    },
    showPeekSidebar() {
      return this.$store.getters.showPeekSidebar || false;
    },
    teamSlug() {
      return this.$store.getters.getTeamSlug;
    },
    isWindowsOS() {
      return (
        navigator.userAgent.indexOf('Windows') !== -1 ||
        navigator.platform.includes('Win') ||
        navigator.appVersion.indexOf('Win') !== -1
      );
    },
    isSettings() {
      return this.$route.path.startsWith(`/${this.teamSlug}/settings/`);
    },
  },
  watch: {
    $route: {
      handler(newVal, oldVal) {
        if (this.goNextClicked) {
          this.goNextClicked = false;
          this.$store.dispatch('previousNextButtonClicked', false);
          return;
        }
        if (this.goPreviousClicked) {
          this.goPreviousClicked = false;
          this.$store.dispatch('previousNextButtonClicked', false);
          return;
        }
      },
      immediate: true,
    },
  },
  mounted() {
    window.addEventListener('keydown', this.keydownHandler);
    this.throttledNavigateButtonClick = throttle(this.navigateButtonClick, 300);
  },
  beforeUnmount() {
    window.removeEventListener('keydown', this.keydownHandler);
  },
  methods: {
    getCard(cardId) {
      return this.$store.getters.getCards[cardId] || {};
    },
    keydownHandler($event) {
      const isBracketLeft = $event.key === '[';
      const isBracketRight = $event.key === ']';
      const isMetaOrCtrl = $event.metaKey || $event.ctrlKey;

      if (isMetaOrCtrl && isBracketLeft) {
        $event.preventDefault();
        this.navigateButtonClick('left');
      } else if (isMetaOrCtrl && isBracketRight) {
        $event.preventDefault();
        this.navigateButtonClick('right');
      }
    },
    clickOnProjectDropdownItem(item_id) {
      const project =
        (this.$store.getters.getTeamProjects || []).find((p) => p.id === item_id) || {};
      if (!project) return;
      this.setAndOpenProject(item_id);
    },
    clickOnBoardDropdownItem(item_id) {
      this.$store.dispatch('setCurrentBoardId', item_id);
      const currentBoard = this.$store.getters.currentBoard || {};
      this.$store.dispatch('setCurrentProjectId', currentBoard.project_id);
      this.$store.dispatch('setCurrentPageID', '');
      this.$store.dispatch('setPage', {});
    },
    clickOnViewDropdownItem(item_id) {
      this.$store.dispatch('setViewEditMode', false);
      this.setViewResultsFromLocal(item_id);
    },
    clickOnCardOrEpicDropdownItem(item_id, type) {
      const card = this.getCard(item_id);
      const dashedCardTitle = getDashedTitle(card.title);
      const path = type === ResourceType.Epic ? this.$constants.epicRoute.path : 'card';
      const route = `/${this.teamSlug}/${path}-${card.id}-${dashedCardTitle}`;
      if (route === this.$route.path) return;
      this.$store.dispatch('setDisplayedCards', [card.id]);
      this.$router.push({
        path: route,
        query: { userNavigated: true },
      });
    },
    handleRecentlyViewedDropdownItemClick(item, hide) {
      hide();
      if (
        item.type !== this.$constants.routeNames.boardCard &&
        !this.isAnyCardDisplayedInDockedLayout
      ) {
        this.$store.dispatch('setDisplayedCards');
      }
      if (!item || this.$route.path === item.path) {
        return;
      }

      switch (item.type) {
        case this.$constants.routeNames.space:
          this.clickOnProjectDropdownItem(item.id);
          break;
        case this.$constants.routeNames.board:
          this.clickOnBoardDropdownItem(item.id);
          break;
        case this.$constants.routeNames.view:
          this.clickOnViewDropdownItem(item.id);
          break;
        case this.$constants.epicRoute.name:
        case this.$constants.routeNames.boardCard:
          this.clickOnCardOrEpicDropdownItem(item.id, item.type);
          break;
      }

      this.pushRoute(item.path);
    },
    isDisabled(direction) {
      if (direction === 'left') {
        return this.historyIndex <= 0;
      }

      return this.historyIndex === this.browseHistory.length - 1;
    },
    goToNextScreen() {
      this.goNextClicked = true;
      this.$store.dispatch('previousNextButtonClicked', true);
      this.$store.dispatch('setElectronBrowseHistoryIndex', this.historyIndex + 1);
      window.history?.forward();
    },
    goToPreviousScreen() {
      this.goPreviousClicked = true;
      this.$store.dispatch('previousNextButtonClicked', true);
      this.$store.dispatch('setElectronBrowseHistoryIndex', this.historyIndex - 1);
      window.history?.back();
    },
    navigateButtonClick(direction) {
      if (this.isDisabled(direction)) return;
      direction === 'left' ? this.goToPreviousScreen() : this.goToNextScreen();
    },
    pushRoute(path) {
      if (this.$route.path !== path) this.$router.push(path);
    },
    fetchBoard(board_id) {
      const flatBoard = this.$store.getters.getFlatBoards[board_id] || {};
      if (!this.$isBoardFullyLoaded(flatBoard)) {
        this.$store.dispatch('fetchBoard', { boardId: board_id }).then(() => {
          this.$store.dispatch('fetchTags', { projectId: this.projectId });
        });
      }
    },
    fetchCard(cardId, type = ResourceType.Card) {
      if (!Object.keys(this.getCard(cardId)).length || this.getCard(cardId).partially_loaded) {
        this.$store.dispatch('fetchCard', { cardId, type }).then((card) => {
          if (type === ResourceType.Card) {
            this.fetchBoard(card.board_id);
          } else if (type === ResourceType.Epic) {
            this.fetchBoard(this.$constants.epicsBoardId);
            this.$store.dispatch('constructInEpicBoard', cardId);
          }
        });
      }
    },
    handleRecentlyViewedDropdownItemHover(item) {
      switch (item.type) {
        case this.$constants.routeNames.board:
          this.fetchBoard(item.id);
          break;
        case this.$constants.routeNames.view:
          this.preloadViewResults(item.id);
          break;
        case this.$constants.routeNames.boardCard:
          this.fetchCard(item.id);
          break;
        case this.$constants.epicRoute.name:
          this.fetchCard(item.id, ResourceType.Epic);
          break;
      }
    },
  },
};
</script>
