<template>
  <div
    class="member-hover-preview-content"
    :class="{ 'hidden-member-preview': hiddenMember }"
  >
    <div class="member-hover-preview-main-row">
      <UserAvatarContent
        class="user-avatar-main"
        :thumbnail-image="member.profile_image || ''"
        :member-initials="memberInitials || ''"
        :member-id="member.id || ''"
        :fallback-style="fallbackStyle"
        :size="'size40'"
        :color="memberColor"
        :hidden-member="hiddenMember"
      >
        <em
          v-if="hiddenMember"
          class="custom-icon flex w-full h-full hidden-member-icon"
          :style="{ ...getCustomIconStyle(iconPath('user')), ...hiddenStyle }"
        />
      </UserAvatarContent>
      <div class="member-hover-preview-labels">
        <div class="flex flex-row">
          <span
            v-if="firstAndLastName"
            class="font-medium text-text-default overflow-hidden text-ellipsis whitespace-nowrap"
          >
            {{ firstAndLastName }}
            &nbsp;
          </span>
          <GuestMemberLabel
            v-if="firstAndLastName && !deactivated"
            :member-role="memberRole"
            :class-style="'overflow-hidden lowercase flex-shrink-0 text-ellipsis whitespace-nowrap'"
          />
          <span
            v-else
            class="overflow-hidden text-ellipsis whitespace-nowrap"
          >
            {{ displayName }}
          </span>
          <GuestMemberLabel
            v-if="!firstAndLastName && !deactivated"
            :member-role="memberRole"
            :class-style="'overflow-hidden ml-1 lowercase text-ellipsis whitespace-nowrap'"
          />
          <span v-if="deactivated">
            &nbsp;
            {{ deactivated }}
          </span>
        </div>
        <span
          v-if="member.role === 'guest' && firstAndLastName"
          class="overflow-hidden text-ellipsis whitespace-nowrap"
        >
          {{ displayName }}
        </span>
        <em
          v-if="member.role === 'guest' && firstAndLastName && displayName"
          class="inline-flex items-center h-1 w-1 mb-0.5 bg-gray-300"
          :style="getCustomIconStyle(iconPath('ellipse'))"
        />
        <span>
          {{ email }}
        </span>
        <div
          v-if="hiddenMember"
          class="text-sm whitespace-normal"
        >
          {{ translate('hiddenMemberDesc') }}
        </div>
      </div>
    </div>
    <ButtonCustom
      v-if="!hiddenMember"
      class="member-hover-preview-button"
      :type="'important'"
      :text="translate('viewWork')"
      @mouseenter="buttonMouseEnterHandler"
      @mousedown="goToUsersWorkingOn"
    />
  </div>
</template>

<script>
import ButtonCustom from '@/components/elements/ButtonCustom';
import UserAvatarContent from '@/components/functional/UserAvatarContent';
import userAvatarContentMixin from '@/mixins/userAvatarContentMixin';
import viewResultsMixin from '@/mixins/viewResultsMixin';
import generateDefaultMyWorkFilters from '@/utilities/generateDefaultMyWorkFilters';
import GuestMemberLabel from '@/components/widgets/GuestMemberLabel';
import customIconsMixin from '@/mixins/customIconsMixin';

export default {
  name: 'MemberHoverPreview',
  components: {
    ButtonCustom,
    UserAvatarContent,
    GuestMemberLabel,
  },
  mixins: [userAvatarContentMixin, viewResultsMixin, customIconsMixin],
  props: {
    member: {
      type: Object,
      required: true,
    },
    showUserColor: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['viewWorkClick'],
  computed: {
    firstAndLastName() {
      if (this.hiddenMember) return `${this.translate('hiddenMember')} (${this.member.id})`;
      const { first_name, last_name } = this.member;
      return `${first_name || ''} ${last_name || ''}`.trim();
    },
    displayName() {
      if (this.hiddenMember) return '';
      const display_name = (this.member.display_name || '').trim();
      return this.firstAndLastName ? `(${display_name})` : display_name;
    },
    email() {
      if (this.hiddenMember) return '';
      return this.member.email || this.member.invitee_email || '';
    },
    deactivated() {
      const inactiveMember = this.$store.getters.getInactiveTeamMembers[this.member.id] || {};
      const isDeactivated = Object.keys(inactiveMember).length > 0;
      return isDeactivated ? ` (${this.translate('deactivated')})` : '';
    },
    selectedTabId() {
      return this.$store.getters.getMyWorkSelectedTabId(this.member.id);
    },
    memberRole() {
      return this.member.role || '';
    },
    hiddenMember() {
      if (this.isGuestRole) {
        const teamMember = this.$store.getters.getTeamMembers[this.member.id];
        return !teamMember;
      }
      return false;
    },
  },
  methods: {
    buttonMouseEnterHandler() {
      const cardFilters = generateDefaultMyWorkFilters(this.member.id, this.selectedTabId);
      this.preloadNewViewResults(`${this.member.id}-${this.selectedTabId}`, cardFilters);
    },
    goToUsersWorkingOn() {
      this.$store.dispatch('removeDisplayedCardsByField', {
        field: 'layout',
        value: this.$constants.docked,
        shouldMatch: false,
      });

      // if you click on your own name, it should not navigate to 'My Work' page
      if (this.member.id === this.$store.getters.getUserId) {
        this.$emit('viewWorkClick');
        if (this.$route.name === this.$constants.myWorkRouteName) return;
        this.$router.push({
          name: 'my-work',
          params: { selectedTabId: this.selectedTabId },
        });
        return;
      }
      if (
        this.$store.getters.getMyWorkUserId !== this.member.id ||
        this.$route.name !== this.$constants.memberRouteName
      ) {
        this.$router.push({
          name: 'member',
          params: {
            userId: this.member.id,
            selectedTabId: this.selectedTabId,
          },
        });
        this.setViewResultsFromLocal(`${this.member.id}-${this.selectedTabId}`);
      }

      this.$emit('viewWorkClick');
    },
  },
};
</script>

<style lang="scss">
.member-hover-preview-content {
  @apply max-w-memberHoverPreview h-memberHoverPreview-H box-border;
  @apply bg-background-strongest border border-solid border-emphasised-border rounded-lg;
  @apply flex flex-col gap-y-2 p-4;

  &.hidden-member-preview {
    max-width: 18rem;
    max-height: 6rem;
  }

  .member-hover-preview-main-row {
    @apply flex flex-row gap-x-2;

    .member-hover-preview-labels {
      @apply text-deEmphasised-text text-sm overflow-hidden overflow-ellipsis whitespace-nowrap;
    }
  }

  .member-hover-preview-button {
    @apply px-2 py-[2px] w-fit h-fit rounded;

    .button-label {
      @apply text-sm text-text-default;
    }
  }
}

// animation class gets added from memberHoverPreviewMixin
.animation {
  animation: fadeInAnimation ease 250ms;
  animation-iteration-count: 1;
  @keyframes fadeInAnimation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
</style>
