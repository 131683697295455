<template>
  <div
    :key="memberId"
    class="avatar"
    :class="[
      { initials: !thumbnailImage && !hiddenMember },
      size,
      { 'border-important-border border-solid border': hiddenMember },
      { inactive: inactive },
    ]"
    :style="fallbackStyle"
  >
    <div
      v-if="!thumbnailImage && !hiddenMember"
      class="avatar-initials"
    >
      {{ memberInitials }}
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'UserAvatarContent',
  props: {
    thumbnailImage: {
      type: String,
      required: true,
    },
    memberInitials: {
      type: String,
      required: true,
    },
    memberId: {
      type: String,
      required: true,
    },
    fallbackStyle: {
      type: Object,
      required: true,
    },
    size: {
      type: String,
      required: false,
      default: 'small',
    },
    inactive: {
      type: Boolean,
      required: false,
      default: false,
    },
    hiddenMember: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>
