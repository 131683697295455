<template>
  <div>
    <div
      v-if="showGroupingLabel"
      class="filter-dropdown-label usn"
    >
      <EmojiIcon
        v-if="item.groupIconType === emojiEnum"
        :emoji="item.groupIcon"
        :classes="'flex justify-center items-center mr-3'"
        :custom-style="{ fontSize: '12px' }"
      />
      <em
        v-else
        :class="{
          'mr-3': item.groupIcon,
        }"
        class="custom-icons"
        :style="[getCustomIconStyle(iconPath(item.groupIcon)), { background: item.groupIconColor }]"
      />
      <span>{{ item.groupLabel }}</span>
      <em
        v-if="item.groupPrivateItem"
        class="custom-icons private-icon"
        :style="[getCustomIconStyle(iconPath('private-project'))]"
      />
    </div>
    <div
      ref="item"
      v-tooltip="{
        content: item.tooltipText,
        placement: 'bottom-start',
        onShow: () => !!item.showTooltip,
      }"
      class="filter-dropdown-item pt usn"
      :class="{
        'high-lighted': highlighted,
        disabled: isDisabled,
        separator: item.separator,
        lighter: item.lighter,
        ctg: item.category,
        stg: item.strong,
      }"
      @click="itemClick"
      @keyup.enter="itemClick"
      @mouseenter="itemMouseEnter"
      @mouseleave="itemMouseLeave"
    >
      <StCheckbox
        v-if="item.checkbox"
        :value="checkedItem"
        :disabled="isDisabled"
      />
      <div
        class="filter-item-icon-label"
        @click="itemRightSideClick"
      >
        <GenericUserAvatarIcon v-if="item.isInvitee && item.customIcon && !item.avatar" />
        <em
          v-else-if="resultType(item) !== emojiEnum && item.customIcon && !item.avatar"
          :style="iconStyle"
          class="custom-icons board-icon flex-shrink-0"
          :class="{
            'bg-icon-default': item.icon === 'avatar',
          }"
        />
        <EmojiIcon
          v-if="resultType(item) === emojiEnum"
          :emoji="item.icon"
          :classes="'mr-2'"
        />
        <UserAvatar
          v-if="item.avatar"
          :member="{ id: item.id }"
        />
        <span
          v-if="item.breadcrumbLabel"
          class="item-breadcrumb text-inactive-text font-medium"
        >
          {{ item.breadcrumbLabel }}<span class="mr-2 ml-2">></span>
        </span>
        <span
          v-member-hover-preview="getMemberHover(item.id)"
          class="item-label"
        >
          {{ item.label }}
        </span>
        <em
          v-if="item.privateItem"
          class="custom-icons private-icon"
          :style="[getCustomIconStyle(iconPath('private-project'))]"
        />
        <GuestMemberLabel
          v-if="!deactivated && memberRole === MemberRole.Guest"
          :member-role="memberRole"
          :class-style="'text-sm lowercase ml-1 text-text-default'"
        />
        <span
          v-if="deactivated"
          class="ml-1"
        >
          {{ deactivated }}
        </span>
        <em
          v-if="showEditIcon"
          v-tooltip="{
            content: translate('editWorkspaceTagsInSettings'),
            placement: 'bottom',
            onShow: () => notEditableTag,
          }"
          :style="getCustomIconStyle(iconPath('edit'))"
          class="custom-icons item-edit-icon ml-auto min-w-4 min-h-4"
          :class="{
            'bg-inactive-icon': notEditableTag,
          }"
          @click.stop="editItem"
        />
        <em
          v-if="showChechIcon"
          :style="getCustomIconStyle(iconPath('check'))"
          class="custom-icons ml-auto mr-0 w-5 h-5 bg-highlight-default shrink-0"
        >
        </em>
      </div>
    </div>
  </div>
</template>

<script>
import GenericUserAvatarIcon from '@/components/elements/GenericUserAvatarIcon';
import isInViewport from '@/utilities/isInViewport';
import EventBus from '@/utilities/eventBus';
import UserAvatar from '../UserAvatar.vue';
import EmojiIcon from '@/components/functional/EmojiIcon.vue';
import GuestMemberLabel from '@/components/widgets/GuestMemberLabel';
import StCheckbox from '@/components/elements/StCheckbox';
import useCustomIcon from '@/utilities/composables/useCustomIcon';
import { MemberRole } from '@superthread-com/api';

export default {
  name: 'FilterDropdownItem',
  components: { UserAvatar, GenericUserAvatarIcon, EmojiIcon, GuestMemberLabel, StCheckbox },

  props: {
    item: {
      type: Object,
      required: true,
      default: () => ({}),
    },

    index: {
      type: Number,
      required: true,
      default: 0,
    },

    selectedItemIndex: {
      type: Number,
      required: false,
      default: -1,
    },

    customIcon: {
      type: Boolean,
      required: false,
      default: false,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    items: {
      type: Array,
      required: false,
      default: () => [],
    },

    disableEditing: {
      type: Boolean,
      required: false,
      default: false,
    },

    selectedItemCheck: {
      type: Number,
      required: false,
      default: -1,
    },
  },

  emits: [
    'item-click',
    'item-hover',
    'item-leave',
    'edit-item',
    'item-right-side-click',
    'scroll-to-item',
  ],
  setup() {
    const { iconPath, getCustomIconStyle } = useCustomIcon();
    return {
      iconPath,
      getCustomIconStyle,
      MemberRole,
    };
  },

  data() {
    return {
      checkedItem: !!this.item.checked,
      rightSideClickEcho: false,
      itemHover: false,
      hoverTimeout: null,
    };
  },

  computed: {
    isDisabled() {
      return this.disabled || this.item.disabled;
    },
    showEditIcon() {
      return (
        (this.item.editable || this.item.isTag) &&
        this.itemHover &&
        !this.isMobile &&
        !this.disableEditing
      );
    },
    notEditableTag() {
      return this.item.isTag && !this.item.editable;
    },
    deactivated() {
      const inactiveMember = this.$store.getters.getInactiveTeamMembers[this.member.id] || {};
      const isDeactivated = Object.keys(inactiveMember).length > 0;
      return isDeactivated ? ` (${this.translate('deactivated')})` : '';
    },
    highlighted() {
      return this.index === this.selectedItemIndex;
    },
    iconStyle() {
      return {
        ...this.getCustomIconStyle(this.iconPath(this.item.icon)),
        ...(this.item.customIconSize && {
          height: `${this.item.customIconSize}px`,
          width: `${this.item.customIconSize}px`,
        }),
        ...(this.item.customIconColor && { background: this.item.customIconColor }),
      };
    },
    showGroupingLabel() {
      const previousItem = this.items[this.index - 1];
      return (
        this.item.groupingItem &&
        (this.index === 0 ||
          (this.index > 0 &&
            (!previousItem.groupingItem || previousItem.groupLabel !== this.item.groupLabel)))
      );
    },

    showChechIcon() {
      return this.selectedItemCheck !== -1 && this.index === this.selectedItemCheck;
    },
    member() {
      return this.getMemberById(this.item?.id);
    },
    memberRole() {
      return this.member.role || '';
    },
  },

  watch: {
    highlighted(newValue) {
      if (newValue) {
        // emit scroll to event when not in viewport
        if (!this.inViewport()) {
          this.$emit('scroll-to-item', { node: this.$refs.item });
        }
      }
      if (!newValue && this.itemHover) {
        this.itemHover = false;
      }
    },
    'item.checked': {
      handler(newValue) {
        this.checkedItem = !!newValue;
      },
      deep: true,
    },
  },

  mounted() {
    if (!this.item.category) {
      EventBus.$on('enterFilterSelect', this.handleEnterSelect);
    }
  },

  unmounted() {
    if (!this.item.category) {
      EventBus.$off('enterFilterSelect', this.handleEnterSelect);
    }
    clearTimeout(this.hoverTimeout);
  },

  methods: {
    getMemberById(id) {
      return this.$store.getters.getMemberById(id);
    },
    getMemberHover(id) {
      const teamMember = this.getMemberById(id);
      if (this.isGuestRole && !Object.keys(teamMember).length) {
        if (id === 'has_members') return teamMember;
        return { id };
      }
      return teamMember;
    },
    resultType(item) {
      return item?.iconType || item?.type;
    },
    editItem() {
      if (!this.item?.editable) {
        return;
      }
      this.$emit('edit-item', this.item);
    },
    itemClick(e) {
      if (this.isDisabled) {
        return;
      }
      if (this.rightSideClickEcho) {
        this.rightSideClickEcho = false;
        return;
      }
      this.checkedItem = !this.checkedItem;
      if (e.key !== 'Enter') {
        e.stopPropagation();
        this.$emit('item-click', this.item);
      }
    },
    itemRightSideClick(e) {
      if (this.item.category) {
        return;
      }
      this.rightSideClickEcho = true;
      if (this.isDisabled) {
        return;
      }
      this.$emit('item-right-side-click', this.item);
    },
    itemMouseEnter() {
      if (this.isDisabled) {
        return;
      }
      clearTimeout(this.hoverTimeout);
      this.hoverTimeout = setTimeout(() => {
        if (this.highlighted) {
          this.itemHover = true;
          clearTimeout(this.hoverTimeout);
        }
      }, 50);
      this.$emit('item-hover', this.item);
    },
    itemMouseLeave() {
      if (this.isDisabled) {
        return;
      }
      clearTimeout(this.hoverTimeout);
      this.itemHover = false;
      this.$emit('item-leave', this.item);
    },
    inViewport() {
      // check if the item element is within the visible bounds of parent vuesceroll
      const { vertical } = isInViewport(this.$refs.item, this.$refs.item.closest('.__vuescroll'));
      return vertical;
    },
    handleEnterSelect(item) {
      if (item.id === this.item.id) {
        this.checkedItem = !this.checkedItem;
      }
    },
  },
};
</script>

<style lang="scss">
.filter-dropdown-item {
  font-size: 14px;
  line-height: 20px;
  height: 40px;
  letter-spacing: 0.2px;
  display: flex;
  align-items: center;
  padding-left: $filterPopupSidePadding;
  font-weight: 500;
  @apply text-text-default;

  .st-checkbox {
    margin-right: unset;

    .checklist-item-icon {
      @apply w-3 h-3 rounded;

      .custom-icons {
        @apply w-3 h-3;
      }
    }
    .checklist-item-text {
      @apply text-sm font-semibold text-text-default;
    }
  }

  .filter-item-icon-label {
    display: flex;
    align-items: center;
    padding-right: $filterPopupSidePadding;
    flex-grow: 1;
    height: 100%;
    overflow: hidden;
  }

  em {
    &:not(.checklist-item-icon *) {
      margin-right: 8px;

      & + em {
        margin-right: 8px;
      }

      & + .filter-item-icon-label {
        & > em {
          margin-right: 8px;
        }
      }

      &.item-edit-icon {
        margin-left: 8px;
      }
    }
  }

  .item-check {
    margin-right: 8px;
    &.checked {
      color: $stBlue;
    }
  }

  &.disabled {
    @apply cursor-default;
  }

  &.lighter {
    font-weight: 400;
    @apply text-text-default;

    &.stg {
      color: $stPrimaryColor;
    }
  }

  &.separator {
    box-shadow: 0px -2px 0px -1px var(--dividers) inset;
  }

  &.high-lighted {
    @apply bg-important-hover;
  }

  .user-avatar-main {
    margin-right: 8px;
  }

  .item-label {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-transform: none;
    @apply text-text-default;
  }

  &.ctg {
    &::first-letter {
      text-transform: capitalize;
    }

    .item-label {
      &::first-letter {
        text-transform: capitalize;
      }
    }
  }
  .trigger {
    @apply flex #{!important};
  }
}
</style>
