import { getCurrentInstance } from 'vue';

export default function useSetAndOpenProject() {
  const _this = getCurrentInstance()?.proxy as any;
  if (!_this) return;

  /**
   * Gets the adjecent project (prioritizes previous one) of the project passed as param
   * then sets it as current project and navigates to it
   */
  const setAndOpenAdjacentProject = (
    projectId: string = _this.$store.getters.getCurrentProjectId
  ) => {
    const newProjectId = _this.$store.getters.getAdjacentProjectId(projectId);
    return setAndOpenProject(newProjectId);
  };

  /**
   * Sets the project passed as current one and navigates to it or fall back to manage spaces
   */
  const setAndOpenProject = (projectId: string) => {
    _this.$store.dispatch('setCurrentProjectId', projectId);

    if (!projectId) {
      return openManageSpaces();
    } else {
      return openProject(projectId);
    }
  };

  const openManageSpaces = () => {
    const isSettings = _this.$route.path.indexOf('/settings/') > -1;
    const toRouteName = isSettings
      ? _this.$constants.settingsManageSpacesRouteName
      : _this.$constants.manageSpacesRouteName;

    if (_this.$route.name !== toRouteName) {
      return _this.$router.push({ name: toRouteName });
    }
    return Promise.resolve();
  };

  const openProject = (projectId: string = '') => {
    const teamSlug = _this.$store.getters.getTeamSlug;
    const tabName = 'boards';
    const projectPath = `${_this.$constants.projectSlug}-${projectId}`;
    const toRoutePath = `/${teamSlug}/${projectPath}/${tabName}`;

    if (_this.$route.path !== toRoutePath) {
      return _this.$router.push(toRoutePath);
    }
    return Promise.resolve();
  };

  return {
    setAndOpenAdjacentProject,
    setAndOpenProject,
  };
}
