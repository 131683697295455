import { ref, Ref, computed } from 'vue';
import { INoteTranscript, IncomingTranscript, TranscriptionState } from '@/types/transcript';
import { nanoid } from 'nanoid';
import constants from '@/utilities/constants';
import { useStore } from 'vuex';

export default function useAudioTranscription(noteId: Ref<string> = ref('newnote')) {
  const store = useStore();
  //@ts-ignore
  const electronAPI = window.electron;

  const transcriptionState = computed((): TranscriptionState => {
    return store.getters.getTranscriptionState;
  });

  // recording control functions
  const startRecording = async () => {
    // if we start recording on new note, create it before transcribing
    const newNoteId = noteId.value === constants.newNote ? await createNote() : noteId.value;

    electronAPI?.send('recording-channel', 'start');
    store.dispatch('setTranscriptionState', {
      recording: 'pending',
      noteId: newNoteId,
      alreadyRecorded: transcriptionState.value.alreadyRecorded.add(newNoteId),
    });

    setupChannels();
  };

  const alreadyRecordedForCurrentNote = computed(() => {
    return transcriptionState.value.alreadyRecorded.has(noteId.value);
  });

  const saveUnsavedTranscripts = async (noteId: string) => {
    const unsavedTranscripts =
      store.getters.getNotes[noteId]?.transcripts.filter((t: INoteTranscript) => t.ongoing) || [];

    if (unsavedTranscripts.length > 0) {
      store
        .dispatch('createTranscript', {
          noteId,
          transcripts: unsavedTranscripts.map((t: INoteTranscript) => ({
            source: t.source,
            content: !t.ongoing ? t.content : `${t.content || ''} ${t.interim_content}`,
          })),
        })
        .then(({ data }: any) => {
          store.dispatch('localSetNoteTranscripts', {
            noteId,
            transcripts: data.note.transcripts,
          });
        });
    }
  };

  const startRecordingForAnotherNote = async (newNoteForRecording: string) => {
    saveUnsavedTranscripts(currentTranscriptionNoteId.value);
    const newNoteId =
      newNoteForRecording === constants.newNote ? await createNote() : newNoteForRecording;

    store.dispatch('setTranscriptionState', {
      noteId: newNoteId,
      alreadyRecorded: transcriptionState.value.alreadyRecorded.add(newNoteId),
    });
  };

  const stopRecording = () => {
    saveUnsavedTranscripts(currentTranscriptionNoteId.value);
    electronAPI?.send('recording-channel', 'stop');
    store.dispatch('setTranscriptionState', { recording: 'stopped', noteId: '' });
    removeChannels();
  };

  const currentTranscriptionNoteId = computed(() => {
    return transcriptionState.value.noteId;
  });

  const isRecordingPending = computed((): boolean => {
    return !!currentTranscriptionNoteId.value && transcriptionState.value.recording === 'pending';
  });

  const isRecordingStarted = computed((): boolean => {
    return !!currentTranscriptionNoteId.value && transcriptionState.value.recording === 'started';
  });

  const isRecordingStopped = computed((): boolean => {
    return transcriptionState.value.recording === 'stopped';
  });

  // create a new note
  const createNote = async () => {
    return store.dispatch('createNewNote', {
      title: '',
    });
  };

  const transcripts = computed((): INoteTranscript[] => {
    return store.getters.getNotes[noteId.value]?.transcripts || [];
  });

  const hasTranscripts = computed((): boolean => {
    return Boolean(transcripts.value.length);
  });

  const isTranscriptDone = (t: IncomingTranscript) => {
    return t.is_final && /^[^]*[.!?]\s*$/.test(t.content.trim());
  };

  const createInterimTranscript = (t: IncomingTranscript) => {
    store.dispatch('localCreateTranscript', {
      noteId: currentTranscriptionNoteId.value,
      transcript: {
        local_id: nanoid(6),
        source: t.source,
        ongoing: true,
        content: '',
        interim_content: t.content,
      },
    });
  };

  const createFinalTranscript = (t: INoteTranscript) => {
    store.dispatch('createTranscript', {
      noteId: currentTranscriptionNoteId.value,
      transcripts: [
        {
          source: t.source,
          content: t.content || '',
        },
      ],
    });
  };

  const processTranscript = async (t: IncomingTranscript) => {
    if (!isRecordingStarted.value || !t.content) return;

    const notes = store.getters.getNotes;
    const note = notes[currentTranscriptionNoteId.value];
    if (!note) return;

    const { transcripts = [] } = note;

    // find last transcription of the same source
    const lastTranscriptIndex = transcripts
      .slice()
      .reverse()
      .findIndex((ti: any) => ti.source === t.source);

    const lastTranscript = transcripts[transcripts.length - lastTranscriptIndex - 1];

    // transcript is considered done if its content is final version of interim result and if it is a finished sentence
    const newTranscriptIsDone = isTranscriptDone(t);

    // if this is the first transcript of the source or if last transcript is done, create a new one
    if (lastTranscriptIndex == -1 || !lastTranscript.ongoing) {
      if (newTranscriptIsDone) {
        createFinalTranscript(t);
      } else {
        createInterimTranscript(t);
      }

      return;
    }

    // if this is a final version of the last transcript, update the content
    if (t.is_final) {
      lastTranscript.content = `${lastTranscript.content} ${t.content}`;
      lastTranscript.interim_content = '';
    } else {
      // if interim result is not done generating, update interim content
      lastTranscript.interim_content = t.content;
    }

    lastTranscript.ongoing = !newTranscriptIsDone;

    // update the transcript locally
    store.dispatch('localUpdateTranscript', {
      noteId: currentTranscriptionNoteId.value,
      transcript: {
        //@ts-ignore
        local_id: lastTranscript.local_id,
        content: lastTranscript.content,
        interim_content: lastTranscript.interim_content,
        ongoing: !newTranscriptIsDone,
      },
    });

    // if transcript is done, save it on the BE
    if (newTranscriptIsDone) {
      createFinalTranscript(t);
    }
  };

  // sets up the transcription channel to listen to incoming transcriptions
  const setupChannels = () => {
    electronAPI.on('transcription-channel', (_: any, t: IncomingTranscript) => {
      processTranscript(t);
    });

    electronAPI.on('recording-channel', (_: any, recording: string) => {
      store.dispatch('setTranscriptionState', {
        ...(recording === 'stopped' && { noteId: '' }),
        recording,
      });

      // TOOD(senad): add a logic to stop recording when recieving 'stopped' event
    });
  };

  const removeChannels = () => {
    electronAPI.removeAllListeners('transcription-channel');
    electronAPI.removeAllListeners('recording-channel');
  };

  // setups up a listener to receive audio permissions
  const audioPermissionsCheck = () => {
    electronAPI.on('permissions-channel', (_: any, perm: string) => {
      const { source, permission_status } = JSON.parse(perm);
      //@ts-ignore
      store.dispatch('setTranscriptionState', {
        permissions: {
          ...transcriptionState.value.permissions,
          [source]: permission_status,
        },
      });
    });

    electronAPI.send('permissions-channel', 'get-microphone');
    electronAPI.send('permissions-channel', 'get-system');
  };

  const hasSystemAudioPermission = computed(() => {
    return transcriptionState.value.permissions.system === 'authorized';
  });

  const hasMicrophonePermission = computed(() => {
    return transcriptionState.value.permissions.microphone === 'authorized';
  });

  const hasAllPermissions = computed(() => {
    return hasSystemAudioPermission.value && hasMicrophonePermission.value;
  });

  const isMicrophonePending = computed(() => {
    return transcriptionState.value.permissions.microphone === 'pending';
  });

  const isSystemAudioPending = computed(() => {
    return transcriptionState.value.permissions.system === 'pending';
  });

  const isMicrophoneAuthorized = computed(() => {
    return transcriptionState.value.permissions.microphone === 'authorized';
  });

  const isSystemAudioAuthorized = computed(() => {
    return transcriptionState.value.permissions.system === 'authorized';
  });

  const requestPermission = (source: 'microphone' | 'system', callback = () => {}) => {
    const permissionStatus = transcriptionState.value.permissions[source];

    switch (permissionStatus) {
      case 'authorized':
        break;
      case 'denied':
        callback();
        break;
      default:
        electronAPI.send('permissions-channel', `request-${source}`);
        break;
    }
  };

  const getPermission = (source: 'microphone' | 'system') => {
    electronAPI.send('permissions-channel', `get-${source}`);
  };

  const getTranscriptContents = (separator: string = '\n') => {
    return transcripts.value.map((t: any) => t.content).join(separator);
  };

  return {
    startRecording,
    startRecordingForAnotherNote,
    stopRecording,
    isRecordingStopped,
    isRecordingPending,
    isRecordingStarted,
    alreadyRecordedForCurrentNote,

    setupChannels,
    removeChannels,

    transcriptionState,
    transcripts,
    hasTranscripts,
    processTranscript,
    isTranscriptDone,

    audioPermissionsCheck,
    hasMicrophonePermission,
    hasSystemAudioPermission,
    hasAllPermissions,
    requestPermission,
    getPermission,

    isMicrophonePending,
    isSystemAudioPending,
    isMicrophoneAuthorized,
    isSystemAudioAuthorized,

    createFinalTranscript,
    createInterimTranscript,
    createNote,

    getTranscriptContents,
    currentTranscriptionNoteId,
  };
}
