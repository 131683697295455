import { ObjectDirective } from 'vue';

const addDragClass = (el: HTMLElement) => {
  if (el && !el.classList.contains('dragging')) {
    el.classList.add('dragging');
  }
};

const removeDragClass = (el: HTMLElement) => {
  if (el) {
    el.classList.remove('dragging');
  }
};

const directive: ObjectDirective = {
  mounted: (el: any, binding: any) => {
    let slider: Element = el;
    const [divPanel] = el.getElementsByClassName('__panel') ?? [];
    if (divPanel) {
      slider = divPanel;
    }
    let isDown = false;
    let startX: number = 0;
    let scrollLeft: number = 0;
    const filteredClasses = binding.value || [];
    const app = document.getElementById('app') as HTMLElement;
    slider.addEventListener('mousedown', (e) => {
      const element = e.target as HTMLElement;
      // check if element is a child of element with filteredClasses
      const closestElement = element.closest('.' + filteredClasses.join(',.'));

      if (filteredClasses.some((c: string) => element.classList.contains(c)) || closestElement) {
        return;
      }
      addDragClass(app);
      isDown = true;
      // @ts-ignore
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    });
    slider.addEventListener('mouseleave', () => {
      removeDragClass(app);
      isDown = false;
    });
    slider.addEventListener('mouseup', () => {
      removeDragClass(app);
      isDown = false;
    });
    slider.addEventListener('mousemove', (e) => {
      // @ts-ignore
      const { alreadyDown } = e;
      if (alreadyDown !== undefined) {
        isDown = alreadyDown;
      }
      if (!isDown) return;
      e.preventDefault();
      // @ts-ignore
      const x = e.pageX - slider.offsetLeft;
      const walk = x - startX;
      slider.scrollLeft = scrollLeft - walk;
    });
  },
};

export default directive;
