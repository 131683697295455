<template>
  <div
    class="flex flex-col items-center justify-center h-full gap-y-6 bg-background-strongest"
    :class="{ 'absolute w-full h-full z-[1000]': showOverlay }"
  >
    <em
      class="custom-icons h-20 w-20 bg-icon-default"
      :style="getCustomIconStyle(iconPath('stlogo'))"
    />
    <span class="text-base text-text-default font-bold">
      {{ labelText }}
    </span>
    <div class="flex p-1 rounded hover:bg-hover-default cursor-pointer">
      <span
        class="text-base text-text-default"
        @click="subLabelClick"
      >
        {{ subLabelText }}
      </span>
    </div>
  </div>
</template>

<script>
import customIconsMixin from '@/mixins/customIconsMixin';

export default {
  mixins: [customIconsMixin],
  data() {
    return {
      redirectPopupTimeout: null,
    };
  },
  computed: {
    redirectUrl() {
      const { code, state } = this.$route.query;
      if (!code || !state) return this.$constants.redirectAfterGoogleSignInRoute;
      return `${this.$constants.redirectAfterGoogleSignInRoute}?code=${code}&state=${state}`;
    },
    showOverlay() {
      return this.$store.getters.getShowRedirectedToAppOverlay;
    },
    labelText() {
      return this.translate(
        this.showOverlay ? 'thisLinkWasOpenedInApp' : 'electronRedirectingToApp'
      );
    },
    subLabelText() {
      return this.translate(this.showOverlay ? 'openHereInstead' : 'electronOpenDesktopApp');
    },
  },
  mounted() {
    //  Desktop redirect component is displayed after redirecting to app from browser:
    //  - when initiating google sign in from desktop app and signing-in in browser
    //  - via 'always open in app' feature => shows component as overlay
    this.$nextTick(() => {
      this.onPageLoad();
      if (!this.showOverlay) {
        this.redirectPopupTimeout = setTimeout(() => {
          this.openDesktopApp();
        }, 200);
      }
    });
  },
  beforeUnmount() {
    clearTimeout(this.redirectPopupTimeout);
  },
  methods: {
    openDesktopApp() {
      window.open(this.redirectUrl, '_self');
    },
    openHereInstead() {
      this.showLoader();
      this.$store.dispatch('setShowRedirectedToAppOverlay', false);
    },
    subLabelClick() {
      this.showOverlay ? this.openHereInstead() : this.openDesktopApp();
    },
    showLoader() {
      const loader = document.createElement('div');
      loader.id = 'loading-wrapper';
      loader.innerHTML = `<div id="bird"></div>
      <div id="load-progress">
        Loading
      </div>`;
      document.body.appendChild(loader);
    },
  },
};
</script>
