<template>
  <section class="box-section">
    <slot></slot>
  </section>
</template>

<script setup lang="ts"></script>

<style lang="scss">
.box-section {
  @apply border border-solid border-standard-border
    rounded-lg bg-important-bg
    w-fit overflow-hidden p-4
    box-border relative text-text-default;
}
</style>
