import cons from '@/utilities/constants';
import isMob from '@/utilities/isMobile';
import { ref, reactive } from 'vue';

/* eslint-disable no-param-reassign */
function install(app, options) {
  // make reactive objects
  const breakpoints = ref({});

  let isMobile = reactive({ value: false });
  isMobile = isMob();

  const updateBreakpoints = (e, key) => {
    breakpoints[key] = e.matches;
  };

  const addMediaMatchers = () => {
    const matchers = {};
    Object.keys(cons.screens).forEach((key) => {
      matchers[key] = window.matchMedia(`(min-width: ${cons.screens[key]})`);
      matchers[key].addListener((e) => updateBreakpoints(e, key));
      updateBreakpoints(matchers[key], key);
    });
  };

  addMediaMatchers();

  // inject global component computed
  app.mixin({
    computed: {
      bps() {
        return breakpoints;
      },
      isAndroid() {
        return navigator.userAgent.match(/android/i);
      },
      isMobile() {
        return isMobile;
      },
      isMobileScreen() {
        return !this.bps.sm;
      },
      isTabletScreen() {
        return this.bps.sm && this.bps.md && !this.bps.lg;
      },
      isDesktopScreen() {
        return this.bps.lg;
      },
    },
  });
}

const BreakpointsPlugin = { install };

export default BreakpointsPlugin;
