import emitter from 'tiny-emitter/instance';

type EventHandler = (...args: any[]) => void;

export default {
  $on(event: string, handler: EventHandler): void {
    emitter.on(event, handler);
  },
  $once(event: string, handler: EventHandler): void {
    emitter.once(event, handler);
  },
  $off(event: string, handler?: EventHandler): void {
    emitter.off(event, handler);
  },
  $emit(event: string, ...args: any[]): void {
    emitter.emit(event, ...args);
  },
};
