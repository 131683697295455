/* eslint-disable class-methods-use-this */
import { authRefresh } from './api';
import { logError } from './sentry';
import { ResourceType } from '@/types/resources';
import useAddCardInGroupByOrder from '@/utilities/composables/useAddCardInGroupByOrder';
import { store } from '@/store';
import { getSprintKey, getSprintListKey } from '@/utilities/sprintKeys';

class SocketService {
  constructor({ storeDispatch, storeGetters, route }) {
    this.storeDispatch = storeDispatch;
    this.storeGetters = storeGetters;
    this.route = route;
    this.baseUrl = `${this.storeGetters.getRoutes.socket}/${this.storeGetters.getTeamID}/websockets/connect`;
    this.authExpired = false;
    this.openStateTimeout = null;
    this.subscriptions = {
      body: {
        subscribe: {
          projects: [],
          boards: [],
          sprints: [],
          cards: [],
        },
        unsubscribe: {
          projects: [],
          boards: [],
          sprints: [],
          cards: [],
        },
        unsubscribe_all: false,
      },
    };
    this.connect();
  }

  waitForConnection(callback, interval = 2000) {
    if (this.ws.readyState === 1) {
      this.openStateTimeout = null;
      clearInterval(this.openStateTimeout);
      callback();
    } else {
      this.openStateTimeout = setTimeout(() => {
        this.waitForConnection(callback, interval);
      }, interval);
    }
  }

  send(data) {
    this.waitForConnection(() => {
      this.ws.send(JSON.stringify(data));
    });
  }

  connect() {
    this.ws = new WebSocket(this.baseUrl);
    this.ws.onopen = this.onOpen;
    this.ws.onmessage = this.onMessage;
    this.ws.onerror = this.onError;
    this.ws.onclose = this.onClose;
  }

  disconnect() {
    this.ws.close();
  }

  onOpen = () => {
    this.storeDispatch('setSocketConnected', true);
    if (this.authExpired) {
      this.resubscribe();
      this.authExpired = false;
    }
  };

  onMessage = (event) => {
    const data = JSON.parse(event.data);
    if (data.same_user) return;
    switch (data.topic) {
      case 'card_archived':
      case 'card_unarchived':
        this.handleCardArchivedRestored(data.body);
        break;
      case 'resource_created':
        this.handleResourceCreated(data.body);
        break;
      case 'resource_updated':
        this.handleResourceUpdated(data.body);
        break;
      case 'resource_deleted':
        this.handleResourceDeleted(data.body);
        break;
      case 'card_moved':
        this.handleCardMoved(data.body);
        break;
      case 'page_moved':
        this.handlePageMoved(data.body);
        break;
      case 'board_moved':
        this.handleBoardMoved(data.body);
        break;
      case 'resource_archived':
        this.handleResourceArchived(data.body);
        break;
      default:
        break;
    }
  };

  // resource type should be: 'projects', 'boards', 'sprints' or 'cards'
  subscribe(resourceType, id) {
    const subscribedList = this.subscriptions.body.subscribe[resourceType];
    const resourceIndex = subscribedList.indexOf(id);
    if (resourceIndex !== -1) return;
    this.subscriptions.topic = 'subscribe';
    subscribedList.push(id);
    this.send(this.subscriptions);
    this.clearUnsubscribeResources();
  }

  clearUnsubscribeResources() {
    this.subscriptions.body.unsubscribe = {
      projects: [],
      boards: [],
      sprints: [],
      cards: [],
    };
  }

  // used for resubscribing to resources after auth expires
  resubscribe() {
    this.subscriptions.topic = 'subscribe';
    this.clearUnsubscribeResources();
    this.subscriptions.body.unsubscribe_all = false;
    this.send(this.subscriptions);
  }

  unsubscribe(resourceType, id) {
    this.subscriptions.topic = 'subscribe';
    // add to unsubscribe
    this.subscriptions.body.unsubscribe[resourceType].push(id);

    // remove from subscribed
    if (this.subscriptions.body.subscribe[resourceType].length > 0) {
      const index = this.subscriptions.body.subscribe[resourceType].findIndex((s) => s === id);
      if (index > -1) {
        this.subscriptions.body.subscribe[resourceType].splice(index, 1);
      }
    }

    this.send(this.subscriptions);
    this.clearUnsubscribeResources();
  }

  /**
   * @param {string} resourceType - projects, boards, cards
   * @param {Array} resourceIds - list of resource ids to add or remove
   * @param {string} addKey - key to add resource to
   * @param {string} removeKey - key to remove resource from
   * @returns {void}
   * @memberof SocketService
   * @description add or remove resource from subscribe or unsubscribe list
   */

  addRemoveResource(
    resourceType,
    resourceIds = [],
    addKey = 'subscribe',
    removeKey = 'unsubscribe'
  ) {
    const addList = this.subscriptions.body[addKey][resourceType] || [];
    const removeList = this.subscriptions.body[removeKey][resourceType] || [];

    resourceIds.forEach((resourceId) => {
      const resourceIndex = addList.indexOf(resourceId);
      if (resourceIndex === -1) {
        addList.push(resourceId);
      }
      if (removeList.length > 0) {
        const index = removeList.indexOf(resourceId);
        if (index > -1) {
          removeList.splice(index, 1);
        }
      }
    });
  }

  /**
   * @param {string} resourceType - projects, boards, cards
   * @param {Array} subscribeTo - list of resource ids to subscribe to
   * @param {Array} unsubscribeFrom - list of resource ids to unsubscribe from
   */
  updateSubscriptions(resourceType, subscribeTo = [], unsubscribeFrom = []) {
    this.subscriptions.topic = 'subscribe';

    this.addRemoveResource(resourceType, subscribeTo);
    this.addRemoveResource(resourceType, unsubscribeFrom, 'unsubscribe', 'subscribe');

    this.send(this.subscriptions);
    this.clearUnsubscribeResources();
  }

  onError(event) {
    logError(event);
  }

  handleAuthRefresh() {
    // send refresh request
    authRefresh()
      .then(() => {
        // reconnect to socket service
        this.connect();
        this.storeDispatch('setSocketConnected', true);
        this.storeDispatch('callReconnectionCallbacks');
      })
      .catch(() => {
        // do not set socket connected to true when auth refresh fails
      });
  }

  onClose = (event) => {
    this.storeDispatch('setSocketConnected', false);
    if (event.reason === 'auth expired') {
      this.authExpired = true;
      this.handleAuthRefresh();
      return;
    }

    // unexpected websocket close error
    if (event.code === 1006) {
      this.connect();
      this.resubscribe();
      this.storeDispatch('setSocketConnected', true);
      this.storeDispatch('callReconnectionCallbacks');
    }
  };

  handleResourceCreated(data) {
    switch (data.resource_type) {
      case 'project':
        this.storeDispatch('localAddProjectToTeamProjects', data.resource);
        break;
      case ResourceType.Card:
        this.storeDispatch('handleCardCreated', {
          listId: data.resource.list_id,
          card: { ...data.resource },
        });
        break;
      case ResourceType.Board:
        this.storeDispatch('handleBoardCreated', {
          board: { ...data.resource },
        });
        break;
      case 'page':
        this.storeDispatch('handlePageCreated', {
          page: { ...data.resource },
        });
        break;
      case ResourceType.Sprint:
        this.storeDispatch('postFetchSprint', { ...data.resource });
        break;
      default:
        break;
    }
  }

  handleResourceUpdated(data) {
    switch (data.resource_type) {
      case 'project':
        if (data.changes.length > 0) {
          this.storeDispatch('localUpdateProject', { id: data.resource_id, changes: data.changes });
        }
        break;
      case ResourceType.Card:
        this.storeDispatch('localUpdateCard', {
          cardId: data.resource_id,
          listId: data.hints.list_id,
          projectId: data.hints.project_id,
          changes: data.changes,
        });
        break;
      case ResourceType.Sprint:
        this.handleSprintUpdated(data);
        break;
      default:
        break;
    }
  }

  handleResourceDeleted(data) {
    switch (data.resource_type) {
      case 'project':
        this.storeDispatch('addToSocketDeletedProjects', data.resource_id);
        this.storeDispatch('localRemoveProject', data.resource_id);
        break;
      case ResourceType.Board:
        this.handleBoardDeleted(data);
        break;
      case ResourceType.Page:
        this.handlePageDeleted(data);
        break;
      case ResourceType.Card:
        this.handleCardDeleted(data);
        break;
      default:
        break;
    }
  }

  handleSprintUpdated(data) {
    this.storeDispatch('localUpdateSprintInProject', {
      id: data.resource_id,
      changes: data.changes,
    });
  }

  handleCardMoved(data) {
    const { addCardInGroupByOrder, removeCardFromGroupByOrder } = useAddCardInGroupByOrder(store);
    const { card } = data;

    const originalListId = this.getListId(
      card.project_id,
      data.original_sprint_id,
      data.original_list_id
    );
    const destinationListId = this.getListId(
      card.project_id,
      data.destination_sprint_id,
      data.destination_list_id
    );
    const originalBoardId = this.getBoardId(
      data.original_project_id,
      data.original_sprint_id,
      data.original_board_id
    );
    const destinationBoardId = this.getBoardId(
      data.destination_project_id,
      data.destination_sprint_id,
      data.destination_board_id
    );

    removeCardFromGroupByOrder(card);
    addCardInGroupByOrder(card);

    this.storeDispatch('moveCardHandler', {
      cardModel: card,
      cardId: data.card_id,
      destinationProjectId: data.destination_project_id,
      destinationBoardId,
      destinationListId,
      destinationPosition: data.destination_position,
      originalProjectId: data.original_project_id,
      originalBoardId,
      originalListId,
      originalPosition: data.original_position,
      route: this.route,
    });
  }

  getListId(projectId, sprintId, listId) {
    return sprintId !== '' ? getSprintListKey(projectId, sprintId, listId) : listId;
  }

  getBoardId(projectId, sprintId, boardId) {
    return sprintId !== '' ? getSprintKey(projectId, sprintId) : boardId;
  }

  handlePageMoved(data) {
    this.storeDispatch('movePageHandler', {
      destinationProjectId: data.destination_project.id,
      originalProjectId: data.original_project.id,
      destinationPages: data.destination_project.pages,
      originalPages: data.original_project.pages,
      destinationPageOrder: data.destination_project.page_order,
      originalPageOrder: data.original_project.page_order,
    });
  }

  handleBoardMoved(data) {
    this.subscribe('projects', data.destination_project.id);
    this.storeDispatch('moveBoardHandler', {
      destinationProjectId: data.destination_project.id,
      originalProjectId: data.original_project.id,
      destinationBoardOrder: data.destination_project.board_order,
      originalBoardOrder: data.original_project.board_order,
      boardId: data.board_id,
    });
  }

  handleCardArchivedRestored(body) {
    const card = body.card;
    this.storeDispatch('localUpdateCardField', {
      cardId: card.id,
      fieldName: 'archived',
      fieldValue: card.archived || false,
    });
    if (card.archived) {
      const listCards = this.storeGetters.getListCards[card.list_id];
      const index = listCards.findIndex((c) => c === card.id);
      if (index > -1) {
        listCards.splice(index, 1);
        const listCardsObj = {};
        listCardsObj[card.list_id] = listCards;
        this.storeDispatch('setListCards', listCardsObj || {});
      }
    } else {
      this.storeDispatch('addCardToListCards', {
        listId: card.list_id,
        cardId: card.id || card.card_id || '',
      });
    }
  }

  handleCardDeleted(data) {
    const card = data.card;

    this.storeDispatch('removeCardFromCards', card.id);
    this.storeDispatch('removeCardFromListCards', {
      listId: card.list_id,
      cardId: card.id,
    });
    this.storeDispatch('removeCardFromGroupByBoard', {
      boardId: card.board_id,
      card: card,
    });

    const viewResult = this.storeGetters.getViewResults;
    const viewCardIndex = viewResult.findIndex((c) => c.id === card.id);
    if (viewCardIndex > -1) {
      viewResult.splice(viewCardIndex, 1);
      this.storeDispatch('setViewResults', viewResult);
      this.storeDispatch('currentViewTotalCount', viewResult.length);
    }

    this.storeDispatch('removeFromElectronRecentlyViewed', {
      currentlyViewed: { id: card.id, type: data.resource_type },
      teamId: this.storeGetters.getTeamID,
    });

    const isCardDisplayed = this.storeGetters.getIsCardDisplayed(card.id);
    if (isCardDisplayed) {
      this.storeDispatch('removeDisplayedCardsByField', {
        field: 'id',
        value: card.id,
      });

      const isOnlyCardDisplayed = this.storeGetters.getDisplayedCards.length === 1;
      if (isOnlyCardDisplayed) {
        this.storeDispatch('itemNotFound', true);
      }
    }
  }

  handlePageDeleted(data) {
    const page = data.page;
    const pageFromTree = this.storeGetters.getProjectPagesTree(page.project_id)[page.id];

    this.storeDispatch('localRemovePageFromPageOrder', {
      projectId: page.project_id,
      pageId: page.id,
    });
    this.storeDispatch('localRemovePagesFromTree', {
      projectId: page.project_id,
      pages: [pageFromTree],
    });
    this.storeDispatch('removeProjectPage', { pageId: page.id, projectId: page.project_id });
    this.storeDispatch('removePage', page.id);

    this.storeDispatch('removeFromElectronRecentlyViewed', {
      currentlyViewed: { id: page.id, type: data.resource_type },
      teamId: this.storeGetters.getTeamID,
    });

    const openedPage = this.storeGetters.getPage;
    if (openedPage.id === page.id) {
      this.storeDispatch('itemNotFound', true);
    }
  }

  handleBoardDeleted(data) {
    const board = data.board;

    const flatBoards = this.storeGetters.getFlatBoards;
    if (flatBoards?.hasOwnProperty(board.id)) {
      delete flatBoards[board.id];
    }
    const boardsArray = Object.values(flatBoards);
    this.storeDispatch('setFlatBoards', boardsArray);

    this.storeDispatch('addBoardInBoards', { id: `${board.id}_notFound`, notFound: true });

    this.storeDispatch('removeFromElectronRecentlyViewed', {
      currentlyViewed: { id: board.id, type: data.resource_type },
      teamId: this.storeGetters.getTeamID,
    });

    const currentBoardId = this.storeGetters.currentBoardId;
    if (currentBoardId === board.id) {
      this.storeDispatch('itemNotFound', true);
    }
  }

  handleResourceArchived(data) {
    switch (data.resource_type) {
      case ResourceType.Board:
        this.storeDispatch('updateBoardPropertyInBoards', {
          boardId: data.resource_id,
          propertyName: 'archived',
          value: data.archived,
        });
        break;
      case ResourceType.Page:
        this.storeDispatch('localUpdatePageProp', {
          pageId: data.resource_id,
          propertyName: 'archived',
          value: data.archived,
        });
        break;
      default:
        break;
    }
  }
}

export default SocketService;
