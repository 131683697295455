import EventBus from '@/utilities/eventBus';

export default {
  methods: {
    hideMemberDropdown() {
      if (this.$store.getters.getCurrentCardIdMemberDropdown) {
        this.$store.dispatch('setCurrentCardIdMemberDropdown', '');
        this.$store.dispatch('setCurrentCardMembersDropdown', {});
      }
    },
    hideCardActionsDropdown() {
      if (this.$store.getters.getCurrentCardIdActionEdit) {
        this.$store.dispatch('setCurrentCardIdForActionEdit', '');
      }
    },

    closeParentCardPopup() {
      if (this.$store.getters.getShowSetParentCardPopup) {
        this.$store.dispatch('setShowSetParentCardPopup', '');
      }
    },

    closeDupicateCardPopup() {
      if (this.$store.getters.getShowDuplicateCardPopup) {
        this.$store.dispatch('setShowDuplicateCardPopup', '');
      }
    },

    closeEmojiPicker() {
      if (this.$store.getters.getEmojiPickerDisplayed) {
        this.$store.dispatch('setEmojiPickerDisplayed', '');
      }
    },

    closePageUploadPopup() {
      if (Object.keys(this.$store.getters.getPageUploadPopup).length > 0) {
        this.$store.dispatch('setPageUploadPopup', {});
      }
    },

    closeRestOfCardDropdowns() {
      // for card sidebar members and parent card pill dropdown
      EventBus.$emit('closeCommentActionsDropdown');
    },
    hideManageProjectsDropdowns() {
      this.hideMemberDropdown();
    },

    hideAllDropdowns() {
      this.hideMemberDropdown();
      this.hideCardActionsDropdown();
      this.closeRestOfCardDropdowns();
      this.hideManageProjectsDropdowns();
      this.closeParentCardPopup();
      this.closeDupicateCardPopup();
      this.closeEmojiPicker();
      this.closePageUploadPopup();
    },
  },
};
