<template>
  <div
    ref="textareaWrap"
    class="textarea"
    :class="{ 'error-placeholder': errorPlaceholder }"
    :style="inputWrapperStyle"
  >
    <textarea
      ref="textField"
      v-model="inputValue"
      class="auto-expand-textarea"
      :class="textareaClass"
      :readonly="disabled"
      :placeholder="placeholder"
      :style="inputStyle"
      :maxlength="maxLength"
      @keydown.enter="enterHandler($event)"
      @input="inputHandler"
      @keydown.esc.stop.exact="$emit('esc', $event)"
      @keypress="keyPressHandler"
      @keydown.tab.prevent="$emit('tabpress', $event)"
      @blur="blurHandler"
      @keyup.enter.prevent
      @keydown.down="arrowDownLastLine"
      @keydown.right="arrowDownRightEnd"
      @keydown="keydownHandler"
      @focus="$emit('focus', $event)"
      @mousedown="$emit('mousedown', $event)"
      @mouseup="$emit('mouseup', $event)"
      @paste="pasteHandler"
    />
    <textarea
      ref="shadow"
      v-model="inputValue"
      class="shadow"
      :class="textareaClass"
      tabindex="0"
    />
  </div>
</template>

<script>
import EventBus from '@/utilities/eventBus';

export default {
  name: 'AutoExpandTextarea',
  props: {
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    errorPlaceholder: {
      type: Boolean,
      required: false,
      default: false,
    },
    textValue: {
      type: String,
      required: false,
      default: '',
    },
    focusField: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    initialHeight: {
      type: Number,
      required: false,
      default: 40,
    },
    textareaClass: {
      type: String,
      required: false,
      default: '',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    maxLength: {
      type: Number,
      required: false,
      default: null,
    },
    handleArrowDownInLastLine: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: [
    'mounted',
    'blur',
    'handleKeypress',
    'scrollBoardListToEnd',
    'enter',
    'arrow-down-last-line',
    'arrowDownRightEnd',
    'esc',
    'tabpress',
    'focus',
    'mousedown',
    'mouseup',
  ],

  data() {
    return {
      value: '',
      inputHeight: this.initialHeight,
      textAreaWrapperHeight: this.initialHeight,
      shakeAnimation: false,
      animationTimeout: null,
    };
  },

  computed: {
    inputValue: {
      get() {
        return this.textValue;
      },
      set(value) {
        this.value = this.textValue;
      },
    },
    inputStyle() {
      return {
        'min-height': this.inputHeight,
      };
    },
    inputWrapperStyle() {
      return {
        'max-height': this.textAreaWrapperHeight,
      };
    },
    isBoard() {
      return this.$route.name === 'board';
    },
    isBoardPreview() {
      return this.$route.name === 'boards';
    },
  },

  watch: {
    inputValue() {
      this.resize();
    },
  },

  beforeMount() {
    window.addEventListener('resize', this.resize);
    this.$nextTick(() => {
      this.resize();
      this.$emit('mounted');
    });
  },
  beforeCreate() {
    this.$nextTick(() => {
      if (this.focusField) {
        this.focusInput();
      }
    });
  },

  unmounted() {
    clearTimeout(this.animationTimeout);
    window.removeEventListener('resize', this.resize);
  },

  methods: {
    pasteHandler(e) {
      e.preventDefault();
      const text = e.clipboardData?.getData('text/plain').trim();
      if (!text) return;
      document.execCommand('insertText', false, text);
    },
    blurHandler($event) {
      this.$emit('blur', $event);
    },
    inputHandler(event) {
      if (event.data && this.isAndroid) {
        const newLineChar = event.target.value.endsWith('\n');
        if (newLineChar) {
          const newValue = event.target.value.replaceAll(/[\r\n]/gm, '');
          Object.assign(event.target, { value: newValue });
          this.value = newValue;

          event.preventDefault();
          this.$nextTick(() => {
            // fire blur event on new line character
            this.blur();
          });
        }
      }
    },
    keydownHandler($event) {
      // if backslash is pressed with ctrl or cmd key
      if ($event.key === '\\' && ($event.ctrlKey || $event.metaKey)) {
        EventBus.$emit('cmdBackslash', $event);
        $event.preventDefault();
      }
    },
    keyPressHandler($event) {
      this.$emit('handleKeypress', $event);
    },
    resize(source) {
      const { textField, shadow } = this.$refs;

      if (textField && shadow) {
        this.$nextTick(() => {
          if (!this.$refs?.textField) return;
          const textFieldStyle = getComputedStyle(this.$refs?.textField);

          const { paddingBottom } = textFieldStyle;
          const { paddingTop } = textFieldStyle;
          const paddingVertical = parseFloat(paddingTop) + parseFloat(paddingBottom);

          const positionData = this.$refs.textField.getBoundingClientRect();
          EventBus.$emit('elementPositionData', positionData);
          if (
            this.textareaClass !== 'quick-card-title' &&
            source !== 'cardResize' &&
            source !== 'closeCard'
          ) {
            EventBus.$emit('scrollCommentsListToEnd', this.textareaClass);
          }
          this.$emit('scrollBoardListToEnd');

          if (shadow.scrollHeight < this.initialHeight) {
            this.inputHeight = `${this.initialHeight}px`;
            this.textAreaWrapperHeight = `${this.initialHeight + paddingVertical}px`;
            return;
          }
          if (this.textValue) {
            this.inputHeight = `${shadow.scrollHeight}px`;
            this.textAreaWrapperHeight = `${parseFloat(shadow.scrollHeight + paddingVertical)}px`;
          } else {
            this.inputHeight = `${this.initialHeight}px`;
            this.textAreaWrapperHeight = `${this.initialHeight}px`;
          }
        });
      }
    },
    exactEnter($event) {
      return !$event.altKey && !$event.ctrlKey && !$event.metaKey && !$event.shiftKey;
    },
    enterHandler($event) {
      $event.preventDefault();
      if (
        this.exactEnter($event) ||
        $event.ctrlKey ||
        $event.metaKey ||
        ((this.isBoard || this.isBoardPreview) && $event.shiftKey)
      ) {
        this.$emit('enter', $event);
        this.clearValue();
      }
    },
    focusInput() {
      this.$nextTick(() => {
        if (this.$refs.textField) {
          this.$refs.textField.select();
          this.resize();
        }
      });
    },
    focus() {
      this.$nextTick(() => {
        if (this.$refs.textField) {
          this.$refs.textField.focus();
          this.$refs.textField.setSelectionRange(this.textValue?.length, this.textValue?.length);
        }
      });
    },
    scrollIntoInput() {
      this.$refs.textField.scrollIntoView();
    },
    clearValue() {
      this.value = '';
    },
    select() {
      this.$refs.textField.select();
    },
    isCursorAtLastLine(textarea) {
      const {
        bottom: textareaBottom,
        top: textareaTop,
        left: textareaLeft,
      } = textarea.getBoundingClientRect();
      const cursorPos =
        textarea.selectionStart === textarea.value.length
          ? textarea.value.length - 1
          : textarea.selectionStart;
      const valueUntilCursor = textarea.value.substring(0, cursorPos);
      const { font, fontSize, lineHeight, padding, border, height } =
        window.getComputedStyle(textarea);

      // detect if there is only one line in the textarea
      const isSingleLine =
        height === lineHeight || Math.round(parseInt(height) / parseInt(lineHeight)) === 1;

      // Create a new span element with the same style as the textarea
      const span = document.createElement('span');
      Object.assign(span.style, {
        position: 'absolute',
        color: 'transparent',
        top: `${textareaTop}px`,
        left: `${textareaLeft}px`,
        width: `${textarea.clientWidth}px`,
        font,
        fontSize,
        lineHeight,
        padding,
        pointerEvents: 'none',
        border,
        wordBreak: 'break-word',
      });
      span.innerText = textarea.value || ' ';
      document.body.appendChild(span);

      // Get the bounding client rect of the cursor
      const range = document.createRange();
      range.setStart(span.firstChild, valueUntilCursor.length);
      range.setEnd(span.lastChild, valueUntilCursor.length);
      const [rect] = range?.getClientRects();
      const cursorX = !textarea.value ? textareaLeft : rect.right;
      const cursorBottom = rect?.bottom;

      // Remove the span
      document.body.removeChild(span);

      return {
        isInLastLine: Math.floor(cursorBottom) >= Math.floor(textareaBottom) || isSingleLine,
        cursorX,
      };
    },

    arrowDownLastLine($event) {
      const { selectionEnd } = this.$refs.textField;
      const { selectionStart } = this.$refs.textField;
      const { length } = this.textValue;
      if (selectionStart !== selectionEnd) {
        return;
      }
      if (this.handleArrowDownInLastLine) {
        const { isInLastLine, cursorX } = this.isCursorAtLastLine(this.$refs.textField);
        if (isInLastLine) {
          this.$emit('arrow-down-last-line', { cursorX });
          $event.preventDefault();
          return;
        }
      }
      if (selectionEnd === length) {
        this.$emit('arrowDownRightEnd', $event);
        $event.preventDefault();
      }
    },
    arrowDownRightEnd($event) {
      this.$nextTick(() => {
        const { selectionEnd } = this.$refs.textField;
        const { selectionStart } = this.$refs.textField;
        const { length } = this.textValue;
        if (selectionStart !== selectionEnd) {
          return;
        }
        if (selectionEnd === length) {
          this.$emit('arrowDownRightEnd', $event);
          $event.preventDefault();
        }
      });
    },
    animateMissingTitle() {
      if (this.$refs.textareaWrap) {
        this.focus();
        this.shakeAnimation = true;
        this.animationTimeout = setTimeout(() => {
          this.shakeAnimation = false;
        }, 820);
      }
    },
    blur() {
      this.$nextTick(() => {
        if (this.$refs.textField) {
          this.$refs.textField.blur();
        }
      });
    },

    setInnerText(value) {
      this.$refs.textField.value = value;
    },
  },
};
</script>

<style lang="scss">
@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
.textarea {
  position: relative;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  &.attention-animation {
    animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
  }

  textarea {
    width: 100%;
    border: none;
    outline: none;
    resize: none;
    overflow: hidden;
    height: 0;
    box-sizing: border-box;

    &.shadow {
      overflow: hidden;
      width: 100%;
      height: 0;
      max-height: 0;
      pointer-events: none;
      opacity: 0;
      margin: 0;
      position: absolute;
      z-index: -1;
      border: none;
    }

    &::placeholder {
      color: var(--placeholderText) !important;
      transition: background-color 0.25s ease-out;
    }
  }

  &.error-placeholder {
    textarea::placeholder {
      @apply bg-destructive-bg;
    }
  }
}
</style>
