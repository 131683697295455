<template>
  <div>
    <input
      v-model="radioButtonValue"
      :class="[`radio-styled-${size}`]"
      type="radio"
      :value="label"
      :name="name"
    />
    <div
      :class="[`check-${size}`, { disabled: disabled }]"
      @click="emitChange"
    />
  </div>
</template>

<script>
export default {
  name: 'RadioButton',
  props: {
    value: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: 'default',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['change'],
  computed: {
    radioButtonValue: {
      get() {
        return this.value;
      },
    },
  },
  methods: {
    emitChange() {
      if (this.disabled) return;
      this.$emit('change', this.label);
    },
  },
};
</script>

<style lang="scss">
$radioSelectedColor: $stBlue;
$radioNormalColor: $gray300;
$disabledRadioColor: $gray400;

input.radio-styled-default[type='radio'],
input.radio-styled-lg[type='radio'],
input.radio-styled-sm[type='radio'] {
  position: absolute;
  visibility: hidden;

  + .check-default,
  + .check-lg,
  + .check-sm {
    display: block;
    border: 2px solid $radioNormalColor;
    border-radius: 100%;
    height: 20px;
    width: 20px;
    z-index: 5;
    transition: border 0.2s linear;
    box-sizing: border-box;

    &.disabled {
      @apply border border-solid border-inactive-border bg-inactive-bg;
    }

    &::before {
      display: block;
      position: absolute;
      content: '';
      border-radius: 100%;
      border: 2px solid transparent;
      height: 8px;
      width: 8px;
      top: 6px;
      left: 6px;
      margin: auto;
      transition: background 0.2s linear;
      box-sizing: border-box;
    }
  }

  + .check-lg {
    box-sizing: content-box !important;
    &::before {
      box-sizing: content-box !important;
    }
  }

  &:checked {
    + .check-default,
    + .check-lg,
    + .check-sm {
      border-color: $radioSelectedColor;
      &:before {
        border-color: $radioSelectedColor;
        background-color: $radioSelectedColor;
      }
    }
  }

  + .check-sm {
    @apply border w-4 h-4;

    &::before {
      @apply mt-0.5 w-[6px] h-[6px] top-[5px] left-[5px];
    }
  }
}
.disabled-radio-button {
  input.radio-styled-default[type='radio'],
  input.radio-styled-lg[type='radio'],
  input.radio-styled-sm[type='radio'] {
    &:checked {
      + .check-default,
      + .check-lg,
      + .check-sm {
        border-color: #e4e7ec;
        &:before {
          border-color: $disabledRadioColor;
          background-color: $disabledRadioColor;
        }
      }
    }
  }
}
</style>
