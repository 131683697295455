<template>
  <div
    v-tooltip="{
      content: tooltip,
      placement: 'top-start',
      onShow: () => tooltip !== '',
    }"
    class="generic-user-avatar-icon"
  >
    <em
      class="custom-icon min-w-[12px] h-3 bg-icon-default m-0"
      :style="getCustomIconStyle(iconPath(icon))"
    />
  </div>
</template>

<script>
import customIconsMixin from '@/mixins/customIconsMixin';
export default {
  name: 'GenericUserAvatarIcon',
  mixins: [customIconsMixin],
  props: {
    icon: {
      required: false,
      type: String,
      default: 'mail',
    },
    tooltip: {
      required: false,
      type: String,
      default: '',
    },
  },
};
</script>
<style lang="scss">
.generic-user-avatar-icon {
  @apply w-6 h-6 rounded-full bg-icon-bg flex shrink-0 items-center justify-center mr-2;
}
</style>
