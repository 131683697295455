import { store } from '@/store';
import constants from '@/utilities/constants';

export default [
  {
    path: `/${constants.routeNames.join}`,
    name: constants.routeNames.join,
    component: () =>
      import(/* webpackChunkName: "authentication" */ '@/components/pages/authentication/Join.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/login',
    name: constants.routeNames.login,
    component: () =>
      import(
        /* webpackChunkName: "authentication" */ '@/components/pages/authentication/Login.vue'
      ),
    meta: { requiresAuth: false },
    beforeEnter: (to, from, next) => {
      const { redirect } = to.query;
      if (redirect) {
        const redirectQuery = redirect.split('?')[1] || '';
        const [key, value] = redirectQuery.split('=') || [];
        if (key === 'updateEmail' && value === 'true') {
          const team_subdomain = redirect.split('/')[1];
          store.dispatch('setRedirectRouteAfterLogin', {
            params: { team_subdomain },
            query: { [key]: value },
            path: redirect,
            fullPath: redirect,
          });
        }
      }
      next();
    },
  },
  {
    path: `/${constants.routeNames.verifyEmail}`,
    name: constants.routeNames.verifyEmail,
    component: () =>
      import(
        /* webpackChunkName: "authentication" */ '@/components/pages/authentication/VerifyEmail.vue'
      ),
    meta: { requiresAuth: false },
    beforeEnter: (to, from, next) => {
      if (!from || from.name !== 'login') {
        return next({ name: 'login' });
      }

      return next();
    },
  },
  {
    // electron app -> sign in with google -> redirect to browser -> select account -> redirect to electron ap -> /oauth2
    path: '/oauth2',
    name: 'oauth2',
    meta: { requiresAuth: false },
    beforeEnter: (to, from, next) => {
      const { code, state } = to.query;

      if (state !== localStorage.getItem('authStateParam') || !code) {
        return next({ name: 'login' });
      }

      localStorage.removeItem('authStateParam');

      // pass authentication code to BE
      return store
        .dispatch('passAuthCode', {
          code,
          redirect_uri: constants.desktopRedirectRoutes[process.env.VUE_APP_ENV],
        })
        .then(() => next({ name: constants.routeNames.workspaces }))
        .catch(() => next({ name: 'login' }));
    },
    component: { template: '<div></div>' }, // Dummy component to satisfy the type
  },
  {
    path: '/desktop-redirect',
    name: 'desktop-redirect',
    meta: { requiresAuth: false },
    component: () =>
      import(
        /* webpackChunkName: "authentication" */ '@/components/pages/authentication/DesktopRedirect.vue'
      ),
  },
];
