import { MutationTree } from 'vuex';
import { EditorState, EditorService } from './state';
import { ResourceType } from '@/types/resources';
import { EditorUpload } from './types';

export default {
  setEditor(state, payload: EditorService) {
    state.editors[payload.id] = payload;
  },
  deleteEditor(state, id) {
    delete state.editors[id];
  },
  setCurrentEditorId(state, id) {
    state.currentEditorId = id;
  },
  setCurrentEditorResourceType(state, resourceType: ResourceType) {
    state.currentEditorResourceType = resourceType;
  },
  updateEditorId(state, { oldId = 'editor-page-newpage', newId, resourceId }) {
    let oldKey = oldId;
    if (oldId === 'editor-page-newpage') {
      oldKey = Object.keys(state.editors).find((key) => key.includes('-editor-page-newpage-'));
    }
    state.editors[oldKey]?.updateEditorId?.(newId);
    state.editors[oldKey]?.updateEditorResourceId?.(resourceId);
    state.editors[newId] = state.editors[oldKey];
    delete state.editors[oldKey];
  },

  setPersistedPagesKeys(state, keys) {
    state.persistedPageKeys = keys;
  },

  addPageKeyToPersistedKeys(state, key) {
    state.persistedPageKeys?.push(key);
  },
  setSuggesterShown(state, payload) {
    state.suggesterShown = payload;
  },
  insertOrUpdateEditorUpload(state, upload: EditorUpload) {
    const existingUpload = state.uploading.find((u) => u?.id === upload.id);
    if (!existingUpload) {
      state.uploading?.push(upload);
    } else {
      Object.assign(existingUpload, upload);
    }
  },
  removeEditorUpload(state, id: string) {
    const index = state.uploading?.findIndex((u) => u.id === id);
    if (index) {
      state.uploading?.splice(index, 1);
    }
  },
} as MutationTree<EditorState>;
