import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, unref as _unref } from "vue"

const _hoisted_1 = ["src", "width", "height"]

import generateSrcUrl from '@/utilities/generateSrcUrl';
import { getCurrentInstance } from 'vue';
import useCustomIcon from '@/utilities/composables/useCustomIcon';


export default /*@__PURE__*/_defineComponent({
  __name: 'ResourceIcon',
  props: {
    src: { default: '' },
    color: { default: '' },
    type: { default: '' },
    imgClass: { default: '' },
    imgStyle: { default: () => ({}) },
    emojiClass: { default: '' },
    emojiStyle: { default: () => ({}) },
    iconClass: { default: '' },
    iconStyle: { default: () => ({}) }
  },
  setup(__props: any) {

const { iconPath, getCustomIconStyle } = useCustomIcon();

const _this = getCurrentInstance()!.proxy as any;

const props = __props;

const generateUrl = (src: string): string => {
  return generateSrcUrl(
    src,
    _this.$cons.resourceIconDefault.width,
    _this.$cons.resourceIconDefault.height,
    true
  );
};

return (_ctx: any,_cache: any) => {
  return (_ctx.type === _ctx.imageEnum && _ctx.src)
    ? (_openBlock(), _createElementBlock("img", {
        key: 0,
        src: generateUrl(_ctx.src),
        alt: "page icon",
        width: _ctx.$cons.resourceIconDefault.width,
        height: _ctx.$cons.resourceIconDefault.height,
        class: _normalizeClass(["object-cover resource-icon", _ctx.imgClass]),
        style: _normalizeStyle(_ctx.imgStyle)
      }, null, 14, _hoisted_1))
    : (_ctx.type === _ctx.emojiEnum && _ctx.src)
      ? (_openBlock(), _createElementBlock("span", {
          key: 1,
          class: _normalizeClass([_ctx.emojiClass, "emoji-icon resource-icon"]),
          style: _normalizeStyle({
      fontSize: '14px',
      width: '16px',
      height: '16px',
      textAlign: 'center',
      display: 'inline-flex',
      ..._ctx.emojiStyle,
    })
        }, _toDisplayString(_ctx.src), 7))
      : (_openBlock(), _createElementBlock("em", {
          key: 2,
          class: _normalizeClass(["custom-icons resource-icon", _ctx.iconClass]),
          style: _normalizeStyle([
      _unref(getCustomIconStyle)(_unref(iconPath)(_ctx.src)),
      { background: _ctx.color || 'var(--iconDefault)' },
      _ctx.iconStyle,
    ])
        }, " ", 6))
}
}

})