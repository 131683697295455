import { getCurrentInstance, ref, watch } from 'vue';
import useOnboardingActions from '@/utilities/composables/useOnboardingActions';
import getBranchName from '@/utilities/getBranchName';
import constants from '@/utilities/constants';

import useIsElectronApp from '@/utilities/composables/useIsElectronApp';
const { isElectronApp } = useIsElectronApp();

const isInput = () => {
  const { tagName, isContentEditable } = document.activeElement as HTMLElement;
  return (
    tagName.toLowerCase() === 'input' || tagName.toLowerCase() === 'textarea' || isContentEditable
  );
};

const isElectronQuickNote = (routeName: string) => {
  return isElectronApp.value && routeName === constants.electronNotesRoute.name;
};

const closeOtherPopups = (modalToOpen: string, getters: any, dispatch: any) => {
  if (modalToOpen !== 'quickCard' && getters.getQuickCardIsOn) dispatch('setQuickCard', false);
  if (modalToOpen !== 'quickPage' && getters.getQuickPage) dispatch('setQuickPage', false);
  if (modalToOpen !== 'quickNote' && getters.getQuickNote) dispatch('setQuickNote', false);
  if (getters.getDeletePopupId) dispatch('deletePopupId', '');
  if (getters.showSearchModal) dispatch('toggleSearchModal', false);
  if (getters.getEmojiPickerDisplayed) dispatch('setEmojiPickerDisplayed', '');
};

const quickCardShortcutHandlerFn = (
  event: KeyboardEvent,
  completeOnboardingActions: Function,
  getters: any,
  dispatch: any,
  routeName: string
) => {
  if (
    !isInput() &&
    (event.code === 'KeyC' || event.key === 'C' || event.key === 'c') &&
    !isElectronQuickNote(routeName)
  ) {
    closeOtherPopups('quickCard', getters, dispatch);
    dispatch('setQuickCard', true);
    return true;
  }
  return false;
};

const quickPageShortcutHandlerFn = (
  event: KeyboardEvent,
  completeOnboardingActions: Function,
  getters: any,
  dispatch: any,
  routeName: string
) => {
  if (
    !isInput() &&
    (event.code === 'KeyP' || event.key === 'P' || event.key === 'p') &&
    !isElectronQuickNote(routeName)
  ) {
    closeOtherPopups('quickPage', getters, dispatch);
    dispatch('setQuickPage', true);
    return true;
  }
  return false;
};

const quickNoteShortcutHandlerFn = (
  event: KeyboardEvent,
  completeOnboardingActions: Function,
  getters: any,
  dispatch: any,
  routeName: string
) => {
  if (
    !isInput() &&
    (event.code === 'KeyN' || event.key === 'N' || event.key === 'n') &&
    !isElectronQuickNote(routeName)
  ) {
    if (isElectronApp.value) {
      //@ts-ignore
      window.electron.send('change-route-channel', {
        path: `${constants.electronNotesRoute.path}-${constants.newNote}`,
        winId: 'notes',
      });
      return true;
    }

    closeOtherPopups('quickNote', getters, dispatch);
    dispatch('setQuickNote', true);
    return true;
  }
  return false;
};

export default function useShortcutHandlers() {
  const _this: any = getCurrentInstance()?.proxy;
  if (!_this) return;

  const { completeOnboardingActions } = useOnboardingActions() as any;

  const quickCardShortcutHandler = ref<(event: KeyboardEvent) => void>(() => {});
  const quickPageShortcutHandler = ref<(event: KeyboardEvent) => void>(() => {});
  const quickNoteShortcutHandler = ref<(event: KeyboardEvent) => void>(() => {});
  const copyGitBranchNameShortcutHandler = ref<(cardId: string | number) => void>(() => {});

  watch(
    () => _this.$store,
    (store) => {
      if (!store) return;

      quickCardShortcutHandler.value = (event: KeyboardEvent) => {
        return quickCardShortcutHandlerFn(
          event,
          completeOnboardingActions,
          store.getters,
          store.dispatch,
          _this.$route.name
        );
      };

      quickPageShortcutHandler.value = (event: KeyboardEvent) => {
        return quickPageShortcutHandlerFn(
          event,
          completeOnboardingActions,
          store.getters,
          store.dispatch,
          _this.$route.name
        );
      };

      quickNoteShortcutHandler.value = (event: KeyboardEvent) => {
        return quickNoteShortcutHandlerFn(
          event,
          completeOnboardingActions,
          store.getters,
          store.dispatch,
          _this.$route.name
        );
      };

      copyGitBranchNameShortcutHandler.value = (cardId: string | number) => {
        const card = store.getters.getCardById(cardId);
        const branchName = getBranchName(cardId, card.title);
        navigator.clipboard?.writeText?.(branchName);

        store.dispatch('addUiNotification', {
          message: _this.translate('branchCopiedToClipboard'),
          icon: 'git-pull-request',
          duration: 4000,
        });
      };
    },
    { immediate: true }
  );

  return {
    copyGitBranchNameShortcutHandler,
    quickCardShortcutHandler,
    quickPageShortcutHandler,
    quickNoteShortcutHandler,
    isInput,
  };
}
