import { createVNode, render } from 'vue';
import tippy from 'tippy.js';
import MemberHoverPreview from '@/components/widgets/MemberHoverPreview';
import { globalState } from '@/utilities/globalState';
import constants from '@/utilities/constants';

const CONSTANTS = {
  HIDE_DELAY: 700,
  DEFAULT_SHOW_DELAY: constants.showMemberHoverPreviewDelay,
  OFFSET: [0, 8],
};

// Single container and instances
const container = document.createElement('div');
let vnode = null;
let showTimeout = null;

const getMemberId = (member) => member.id || member.user_id || member.invitee_user_id || '';

const updateVNodeProps = (member, animationEnabled) => {
  if (vnode?.component) {
    vnode.component.props.member = member;
    vnode.component.props.class = { animation: animationEnabled };
    vnode.component.update();
  }
};

const createVNodeWithProps = (member, animationEnabled) => {
  return createVNode(MemberHoverPreview, {
    member,
    class: { animation: animationEnabled },
    onViewWorkClick: () => {
      globalState.memberHoverTippyInstance?.hide?.();
    },
  });
};

const initializeTippy = (el) => {
  if (globalState.memberHoverTippyInstance) return;
  globalState.memberHoverTippyInstance = tippy(document.createElement('div'), {
    content: container,
    appendTo: document.body,
    placement: 'top-start',
    role: 'poper',
    interactive: true,
    duration: 0,
    arrow: false,
    offset: CONSTANTS.OFFSET,
    triggerTarget: el,
    getReferenceClientRect: () => el.getBoundingClientRect(),
    onUntrigger: () => clearTimeout(showTimeout),
    onHide: () => {
      window.removeEventListener('keypress', globalState.memberHoverTippyInstance.hide);
      clearTimeout(globalState.currentGroupAvatarTimeout);
      globalState.currentGroupAvatarTimeout = setTimeout(() => {
        if (!globalState.memberHoverTippyInstance.state.isVisible) {
          globalState.currentGroupAvatarId = null;
        }
      }, CONSTANTS.HIDE_DELAY);
      globalState.memberHoverPreviewShown = false;
    },
  });
};

const createShowTooltip = (el, binding) => {
  return () => {
    const groupAvatarId = binding.arg?.groupAvatarId;
    const delay =
      globalState.currentGroupAvatarId === groupAvatarId ? 0 : CONSTANTS.DEFAULT_SHOW_DELAY;

    // Update vnode props
    updateVNodeProps(binding.value, delay !== 0);

    // First time setup if needed
    if (!vnode?.component) {
      vnode = createVNodeWithProps(binding.value, delay);
      vnode.appContext = binding.instance.$.appContext;
      render(vnode, container);
      updateVNodeProps(binding.value, delay !== 0);
    }

    initializeTippy(el);

    // Update tippy instance
    globalState.memberHoverTippyInstance?.setProps?.({
      content: container,
      triggerTarget: el,
      getReferenceClientRect: () => el.getBoundingClientRect(),
      delay: [delay, 0],
      onShow: () => {
        if (!el.isConnected || binding.arg?.disabled) return false;
        window.addEventListener('keypress', globalState.memberHoverTippyInstance.hide);
        globalState.memberHoverPreviewShown = true;
        if (globalState.currentGroupAvatarTimeout) {
          clearTimeout(globalState.currentGroupAvatarTimeout);
        }
        return true;
      },
    });

    clearTimeout(showTimeout);
    globalState.currentGroupAvatarId = groupAvatarId;
    showTimeout = setTimeout(() => globalState.memberHoverTippyInstance?.show?.(), delay);
  };
};

export default {
  mounted(el, binding) {
    const member = binding.value;
    const memberId = getMemberId(member);
    const disabled = binding.arg?.disabled;

    if (!memberId || !Object.keys(member).length || disabled) return;

    // Create single tippy instance if it doesn't exist
    if (!vnode || !vnode.component) {
      vnode = createVNodeWithProps(binding.value, false);
      vnode.appContext = binding.instance.$.appContext;
    }
    initializeTippy(el);

    const showTooltip = createShowTooltip(el, binding);
    el.addEventListener('mouseenter', showTooltip);
    el._tippyListeners = { mouseenter: showTooltip };
  },

  unmounted(el) {
    // Remove event listeners
    if (el._tippyListeners) {
      Object.entries(el._tippyListeners).forEach(([event, listener]) => {
        el.removeEventListener(event, listener);
      });
      delete el._tippyListeners;
    }
  },

  updated(el, binding) {
    const disabled = binding.arg?.disabled;

    if (disabled && el._tippyListeners) {
      // directive became disabled
      Object.entries(el._tippyListeners).forEach(([event, listener]) => {
        el.removeEventListener(event, listener);
      });
      delete el._tippyListeners;
      return;
    }

    const memberId = getMemberId(binding.value);
    if (!memberId || !Object.keys(binding.value).length) return;

    const attachListeners = () => {
      requestAnimationFrame(() => {
        // Create new showTooltip with updated member
        if (!vnode || !vnode.component) {
          vnode = createVNodeWithProps(binding.value, false);
        }
        const showTooltip = createShowTooltip(el, binding);
        el.addEventListener('mouseenter', showTooltip);
        el._tippyListeners = { mouseenter: showTooltip };
      });
    };

    if (binding.oldValue.id !== binding.value.id) {
      // when id of member changed, remove old event listener
      if (el._tippyListeners) {
        el.removeEventListener('mouseenter', el._tippyListeners?.mouseenter);
      }
      attachListeners();
    }

    // if member id is the same, bud directive became enabled
    if (binding.oldValue.id === binding.value.id && !disabled && !el._tippyListeners) {
      attachListeners();
    }
  },
};
