import { PageState } from '@/types';
import { browserStorageGetItem } from '@/utilities/browser-storage.util';
import { getParentPageForAddKey } from '@/utilities/localDrafts';

const getDefaultPageState = (): PageState => ({
  pageVersions: [],
  currentPageID: '',
  currentPageMode: 'view',

  archivedPages: [],
  page: {},
  projectPages: {},
  pages: {},
  newPageCreating: false,

  pagesEntity: 'pages',
  loadingPages: true,
  pageCreating: false,
  imageUploadPopup: false,
  coverImageUploadPopup: false,
  pageIconUploadPopup: false,
  insertLinkPopup: false,
  inlineCommentPopup: false,
  lastSidebarItemAdded: '',
  parentPageIdForAdd: 'root',
  projectPagesTree: {},
  sidebarDraftPage:
    browserStorageGetItem(getParentPageForAddKey(browserStorageGetItem('currentTeamId') || '')) ??
    {},
  archivePages: {},
  archivePagesTree: {},
  parentPageExpanded: {},
  pageUploadPopup: {},
  pageRenamingId: '',
  commentsSchema: 1,
  pagesSchema: 1,
  quickPageFilesToUpdate: [],
  pageTemplates: {},
  highlightedPageLevelComments: [],
  pageLevelCommentInputActive: false,
  pageTemplateDraft: {
    title: '',
    icon: {},
    project_ids: [],
    page_title: '',
    page_content: '',
  },
  pageEditorDisplay: {},
  pageIdForMove: '',
});

export default getDefaultPageState;
