import { ObjectDirective } from 'vue';

const directive: ObjectDirective = {
  mounted: (el, binding) => {
    if (!binding.value) return;
    setTimeout(() => {
      el.focus();
    }, 0);
  },
};

export default directive;
