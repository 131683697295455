import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createTextVNode as _createTextVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  ref: 'hiddenInput',
  readonly: "true",
  class: "hidden-input",
  "data-hidden-input": "true"
}
const _hoisted_2 = { class: "text-sm font-sans" }
const _hoisted_3 = { class: "text-text-default whitespace-normal break-words overflow-visible" }
const _hoisted_4 = {
  key: 0,
  class: "pt-2 text-sm text-text-default font-sans"
}
const _hoisted_5 = { class: "mt-2" }
const _hoisted_6 = { class: "flex flex-wrap mt-[24px]" }

import StInput from '@/components/elements/StInput';
import { ResourceType } from '@/types/resources';
import { getCurrentInstance, computed, ref, onMounted, onUnmounted, nextTick } from 'vue';
import useTranslate from '@/utilities/composables/useTranslate';
import useDeleteResource from '@/utilities/composables/useDeleteResource';
import { Portal } from 'portal-vue';
import { useConfirmDialog } from '@vueuse/core';
import ConfirmationModal from '@/components/widgets/ConfirmationModal.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'DeleteResource',
  setup(__props) {

const confirmDialog = useConfirmDialog();
const { translate } = useTranslate();
const _this = getCurrentInstance()!.proxy as any;

const titleInput = ref('');
const type = ref('');
const id = ref('');
const hiddenInput = ref<any>(null);

const { deleteResource, resourceTitle } = useDeleteResource(id, type);

onMounted(() => {
  [type.value, id.value] = _this.$store.getters.getDeletePopupId.split('-');
  window.addEventListener('keydown', keyHandler);

  focusHiddenInput();
  confirmDialog.reveal();
});

onUnmounted(() => {
  window.removeEventListener('keydown', keyHandler);
});

const keyHandler = ($event: KeyboardEvent) => {
  $event.stopPropagation();

  if ($event.key === 'Escape') {
    closePopup();
  } else if ($event.key === 'Enter') {
    handleDelete();
  }
};

// labels and input

const popupText = computed((): string => {
  const key = ResourceType.Epic === type.value ? 'epicWillBeDeleted' : 'resourceWillBeDeleted';
  return [ResourceType.View, ResourceType.Epic, ResourceType.Card].includes(type.value)
    ? translate(key, {
        resourceType: translate(type.value).toLowerCase(),
        resourceTitle: translate(resourceTitle.value),
      })
    : translate('resourceAndContentWillBeDeleted', {
        resourceType: translate(type.value).toLowerCase(),
        resourceTitle: translate(resourceTitle.value),
      });
});

const resourceNameLabel = computed((): string => {
  switch (type.value) {
    case ResourceType.Project:
      return translate('spaceNameLabel');
    case ResourceType.Board:
      return translate('boardName');
    case ResourceType.List:
      return translate('listNameLabel');
    default:
      return translate('name');
  }
});

const deleteItemLabel = computed((): string => {
  return translate('deleteItem', { item: translate(type.value).toLowerCase() });
});

const isInputValid = computed((): boolean => {
  return (
    !!titleInput.value &&
    !!resourceTitle.value &&
    titleInput.value.toLowerCase() == resourceTitle.value.trim().replace(/\s+/g, ' ').toLowerCase()
  );
});

const isInputRequired = computed((): boolean => {
  return [ResourceType.Project, ResourceType.Board, ResourceType.List].includes(type.value);
});

// delete function

const handleDelete = async () => {
  if (isInputRequired.value && !isInputValid.value) return;

  closePopup();
  try {
    await deleteResource();
    displayNotification(true);
  } catch {
    displayNotification(false);
  }
};

// helpers

const focusHiddenInput = () => {
  nextTick(() => hiddenInput.value?.focus());
};

const closePopup = () => {
  _this.$store.dispatch('deletePopupId', '');
};

const displayNotification = (success = true) => {
  const interpolationData = {
    name: resourceTitle.value,
    type: translate(type.value).toLowerCase(),
  };
  const message = translate(success ? 'deleteSuccess' : 'deleteError', interpolationData);

  _this.$store.dispatch('addUiNotification', {
    message,
    icon: 'info',
    duration: 4000,
  });
};

// buttons

const confirmButtonClasses = computed(() => {
  return `${!isInputRequired.value || isInputValid.value ? 'red-active' : ''}`;
});

return (_ctx: any,_cache: any) => {
  const _directive_autofocus = _resolveDirective("autofocus")!

  return (_unref(confirmDialog).isRevealed.value)
    ? (_openBlock(), _createBlock(_unref(Portal), {
        key: 0,
        to: "root-widget"
      }, {
        default: _withCtx(() => [
          _createVNode(ConfirmationModal, {
            class: "custom-popup-wrap",
            title: deleteItemLabel.value,
            "width-class": 'w-[354px]',
            "confirm-button-text": deleteItemLabel.value,
            "confirm-button-class": confirmButtonClasses.value,
            "confirm-button-variation": 'large-fixed',
            "confirm-button-type": 'secondary',
            "cancel-button-type": 'secondary',
            "button-wrap-class": 'flex mt-6',
            onConfirm: handleDelete,
            onCancel: closePopup
          }, {
            content: _withCtx(() => [
              _withDirectives(_createElementVNode("input", _hoisted_1, null, 512), [
                [_directive_autofocus]
              ]),
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("span", _hoisted_3, _toDisplayString(popupText.value), 1)
              ]),
              (isInputRequired.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createElementVNode("span", _hoisted_5, _toDisplayString(_unref(translate)('ifYouWantToDeleteResource', { resourceType: _unref(translate)(type.value).toLowerCase() })), 1),
                    _createElementVNode("span", _hoisted_6, [
                      _createTextVNode(_toDisplayString(resourceNameLabel.value) + ":  ", 1),
                      _createElementVNode("span", {
                        class: "font-medium select-text",
                        onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
                      }, _toDisplayString(_unref(resourceTitle)), 1)
                    ]),
                    _createVNode(_unref(StInput), {
                      ref: "deleteCheckInput",
                      value: titleInput.value,
                      "text-value": titleInput.value,
                      "input-height": 36,
                      "label-text": '',
                      "input-type": 'text',
                      "placeholder-text": _unref(translate)('itemName'),
                      class: "w-[320px]",
                      onInput: _cache[1] || (_cache[1] = ($event: any) => (titleInput.value = $event)),
                      onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"])),
                      onEnterKey: handleDelete
                    }, null, 8, ["value", "text-value", "placeholder-text"])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["title", "confirm-button-text", "confirm-button-class"])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}
}

})