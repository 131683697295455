<template>
  <div class="dropdown-wrap">
    <v-popover
      interactive
      tag="div"
      content-tag="div"
      :offset="[0, 1]"
      :role="'poper'"
      :trigger="'click'"
      :append-to="documentBody"
      :on-show="onPopperOpen"
      :on-hide="onPopperClose"
      :on-trigger="onPopperTrigger"
      :on-untrigger="onPopperUntrigger"
    >
      <template #default="{ state }">
        <div
          :class="[dropdownClass, { 'empty-list': !items.length }]"
          :style="dropdownWrapStyle"
        >
          <div
            class="label-wrap"
            :class="{ 'without-label': !label }"
          >
            <span
              v-if="label"
              class="dropdown-label"
            >
              {{ label }}</span
            >
            <span
              :class="{
                'dropdown-selected-disabled text-inactive-text': disabled,
                'dropdown-selected': !disabled,
                'text-text-placeholder': selectedItem === -1,
              }"
            >
              {{ items.length ? selectedItemText : emptyListText || translate('emptyList') }}
            </span>
          </div>
          <em
            v-if="!disabled && items.length"
            :style="getCustomIconStyle(iconPath('chevron-up'))"
            class="custom-icons dropdown-wrap-arrow-down"
            :class="{ 'rotate-180': !state.isVisible }"
          />
        </div>
      </template>
      <template #content="{ hide, state }">
        <ListDropdown
          v-if="state.isVisible"
          ref="listDropdown"
          class="list-dropdown-popover"
          :items="items"
          :position="dropdownPosition"
          :width="dropdownWidth"
          :style="dropdownStyle"
          :selected-item="selectedItem"
          :disable-scroll="disableScroll"
          :show-tick-on-selected="showTickOnSelected"
          @click="
            (item, index) => {
              handleItemClick(item, index);
              hide();
            }
          "
          @closeEscape="hide"
        />
      </template>
    </v-popover>
  </div>
</template>

<script>
import ListDropdown from '@/components/widgets/ListDropdown';
import customIconsMixin from '@/mixins/customIconsMixin';

export default {
  name: 'ListDropdownWrap',
  components: {
    ListDropdown,
  },
  mixins: [customIconsMixin],
  props: {
    label: {
      type: String,
      required: false,
      default: '',
    },
    height: {
      type: Number,
      required: false,
      default: 44,
    },
    dropdownWidth: {
      type: Number,
      required: false,
      default: 290,
    },
    items: {
      type: Array,
      required: false,
      default: () => [],
    },
    dropdownPosition: {
      type: String,
      required: false,
      default: 'list-dropdown-wrap',
    },
    selectedItem: {
      type: Number,
      required: false,
      default: -1,
    },
    dropdownStyle: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    emptyListText: {
      type: String,
      required: false,
      default: '',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    disableScroll: {
      type: Boolean,
      required: false,
      default: false,
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },

    dropdownPlacement: {
      type: String,
      required: false,
      default: 'bottom-start',
    },

    showTickOnSelected: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['item-click', 'toggle-dropdown'],
  data() {
    return {
      showDropdown: false,
      documentBody: document.body,
    };
  },
  computed: {
    dropdownClass() {
      const dropDownClass = this.disabled || !this.items.length ? 'border-inactive-bg' : 'pt';
      return this.showDropdown
        ? 'dropdown-button pt usn dropdown-opened'
        : `dropdown-button ${dropDownClass} usn dropdown-closed`;
    },
    dropdownWrapStyle() {
      return {
        height: `${this.height}px`,
      };
    },
    selectedItemText() {
      if (this.selectedItem === -1) return this.translate(this.placeholder);
      return this.items[this.selectedItem] ? this.items[this.selectedItem].label : '';
    },
  },
  methods: {
    handleItemClick(item, index) {
      this.$emit('item-click', item, index);
      this.onPopperClose();
    },

    onPopperOpen() {
      if (this.disabled) return false;
      this.showDropdown = true;
      this.$emit('toggle-dropdown', this.showDropdown);
      return true;
    },

    onPopperUntrigger(i, e) {
      e.stopPropagation();
    },

    onPopperTrigger(i, e) {
      e.stopPropagation();
    },

    onPopperClose() {
      this.showDropdown = false;
    },
  },
};
</script>

<style lang="scss">
.dropdown-wrap {
  position: relative;

  .dropdown-popper {
    > div {
      width: 100%;
    }
  }

  .dropdown-opened {
    border: 1px solid #73b9ff;
    box-shadow: 0px 0px 0px 4px #e5f3ff80;
  }

  .dropdown-closed {
    border: 1px solid #dadcdf;
    box-shadow: 0px 1px 2px 0px #1018280d;
  }

  .dropdown-button {
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    padding: 0px 14px;
    align-items: center;
    @apply text-text-default bg-important-bg;

    &.empty-list {
      border: 1px solid $error400;
    }

    .label-wrap {
      display: flex;
      flex-direction: column;
      overflow: hidden;

      &.without-label {
        flex-direction: row;
        align-self: center;
      }

      .dropdown-label {
        font-size: 11px;
        line-height: 16px;
        letter-spacing: 0.2px;
        color: #929292;
        margin-bottom: 1px;
      }

      .dropdown-selected {
        font-size: 14px;
        line-height: 20px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        @apply text-text-default;
      }

      .dropdown-selected-disabled {
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.2px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        @apply text-text-default;
      }

      &.empty-list {
        align-self: center;
      }
    }

    .dropdown-expand-icon {
      font-size: 24px;
      color: #707070;
      align-self: center;
    }
  }
}
</style>
