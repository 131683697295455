export const lastVisitedNoteIdKey = 'last-visited-note-id';
export const quickPageNoteKey = 'quick-page-note-temp-value';
export const notesSidebarWidthKey = 'notes-sidebar-width';
export const noteAiTitlePrompt =
  'Generate a concise title from the following notes and transcript. The title should be 5 words or less that captures the essence of the content.';

export enum NoteContentType {
  UserNotes = 'user_notes',
  AiNotes = 'ai_notes',
  Transcript = 'transcript',
}

export enum NoteTemplate {
  Tidied = 'tidy_up',
  CustomerCall = 'customer_call',
  RecurringMeeting = 'recurring_meeting',
}
