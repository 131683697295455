import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

import { computed } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'Header',
  props: {
    title: { default: '' },
    subtitle: { default: '' }
  },
  setup(__props: any) {

const props = __props;

const titleClass = computed(() => (typeof props.title === 'string' ? '' : props.title.class));
const subtitleClass = computed(() =>
  typeof props.subtitle === 'string' ? '' : props.subtitle.class
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("header", null, [
    _renderSlot(_ctx.$slots, "title", {}, () => [
      (props.title)
        ? (_openBlock(), _createElementBlock("h1", {
            key: 0,
            class: _normalizeClass(titleClass.value)
          }, _toDisplayString(typeof props.title === 'string' ? props.title : props.title.value), 3))
        : _createCommentVNode("", true)
    ]),
    _renderSlot(_ctx.$slots, "subtitle", {}, () => [
      (props.subtitle)
        ? (_openBlock(), _createElementBlock("h2", {
            key: 0,
            class: _normalizeClass(subtitleClass.value)
          }, _toDisplayString(typeof props.subtitle === 'string' ? props.subtitle : props.subtitle.value), 3))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})