import styleVariables from '@/assets/scss/variables.module.scss';

export default {
  computed: {
    theme() {
      return this.$store.getters.getTheme;
    },
    key() {
      const defaultStyle = {
        background: styleVariables.white,
        text: styleVariables.gray800,
        border: styleVariables.gray500,
      };

      if (this.theme === 'dark') {
        return {
          background: styleVariables.gray800,
          text: styleVariables.gray200,
          border: styleVariables.gray400,
        };
      }

      return defaultStyle;
    },
  },
};
