import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import { createApp } from 'vue';
import App from '@/components/pages/App';
import router from './router';
import { store } from './store';
import './registerServiceWorker';
import './assets/scss/app.scss';

// 3rd party libs
import i18next from 'i18next';
import I18NextVue from 'i18next-vue';
import en from '@superthread-com/localization/languages/en';
import PortalVue from 'portal-vue';
import VueTippy from 'vue-tippy';
import vuedraggable from 'vuedraggable';
import { ObserveVisibility } from 'vue-observe-visibility';
import { createHead, VueHeadMixin } from '@unhead/vue';

// mixins
import removeLoader from '@/mixins/removeLoader';
import isGuestRoleMixin from '@/mixins/isGuestRoleMixin';
import isSafari from '@/mixins/isSafari';
import iconTypeMixin from '@/mixins/iconTypeMixin';
import draggingIsOn from '@/mixins/draggingIsOn';
import viewRouteCheckMixin from '@/mixins/viewRouteCheckMixin';

// directives
import vtippy from '@/utilities/vtippy.directive';
import vClickOutside from 'v-click-outside';
import autofocus from '@/utilities/autofocus.directive';
import draghover from '@/utilities/draghover.directive';
import dragToScroll from '@/utilities/dragToScroll.directive';
import MemberHoverPreview from '@/utilities/member-hover-directive';

// globals
import { MessageTypes } from '@/types/messageTypes';
import posthogPlugin from '@/utilities/posthog';
import { changeLanguage } from '@/utilities/languages';
import constants from '@/utilities/constants';
import breakpoints from '@/plugins/BreakpointsPlugin';
import capitalizeFirstLetter from '@/utilities/capitalizeFirstLetter';
import isBoardFullyLoaded from '@/utilities/isBoardFullyLoaded';

import vuescroll from 'vuescroll/dist/vuescroll-esm.js';
import vuescrollOptions from '@/utilities/vuescrollOptions';

import VueVirtualScroller from 'vue-virtual-scroller';

const metaManager = createHead();

const app = createApp(App);
app.use(router);
app.use(store);
app.directive('autofocus', autofocus);
app.directive('tippy', vtippy);
app.directive('draghover', draghover);
app.directive('dragtoscroll', dragToScroll);
app.directive('observe-visibility', {
  beforeMount: (el, binding, vnode) => {
    // @ts-ignore
    vnode.context = binding.instance;
    ObserveVisibility.bind(el, binding, vnode);
  },
  // @ts-ignore
  update: ObserveVisibility.update,
  unmounted: ObserveVisibility.unbind,
});
const { bind, unbind } = vClickOutside.directive;
app.directive('click-outside', {
  mounted(el, bindling) {
    bind(el, { value: bindling.value });
  },
  beforeUnmount(el) {
    unbind(el);
  },
});
app.directive('member-hover-preview', MemberHoverPreview);

app.use(VueTippy, {
  directive: 'tooltip',
  component: 'VPopover',
  defaultProps: {
    placement: 'bottom-start',
    duration: [300, 0],
    maxWidth: 'none',
    popperOptions: {
      modifiers: [
        {
          name: 'preventOverflow',
          options: {
            altAxis: true,
          },
        },
      ],
    },
  },
});
app.use(VueVirtualScroller);
app.use(metaManager);
app.use(posthogPlugin);
// eslint-disable-next-line vue/component-definition-name-casing
app.component('draggable', vuedraggable);
// @ts-ignore
window.StVueApp = app;

app.use(I18NextVue, { i18next });
app.use(PortalVue);
app.mixin(VueHeadMixin);
app.mixin(removeLoader);
app.mixin(isSafari);
app.mixin(isGuestRoleMixin);
app.mixin(iconTypeMixin);
app.mixin(draggingIsOn);
app.mixin(viewRouteCheckMixin);
app.use(breakpoints);
app.use(vuescroll, vuescrollOptions);
// add global properties
app.config.globalProperties.$constants = constants;
app.config.globalProperties.$cons = constants;
app.config.globalProperties.$capitalizeFirstLetter = capitalizeFirstLetter;
app.config.globalProperties.$messageTypes = MessageTypes;
app.config.globalProperties.$isBoardFullyLoaded = isBoardFullyLoaded;
app.config.globalProperties.$blurhashWorker = new Worker(
  new URL(/* webpackChunkName: "blurHashWorker" */ './workers/blurhashWorker', import.meta.url),
  { type: 'module' }
);
app.config.globalProperties.translate = (
  key: string,
  interpolations: Record<string, string> = {}
) => {
  return i18next.t(key, { ...interpolations, interpolation: { escapeValue: false } });
};
app.config.globalProperties.changeLanguage = (language: string) =>
  changeLanguage(i18next, language);

i18next.init({
  lng: 'en',
  fallbackLng: 'en',
  supportedLngs: ['en', 'fr', 'bs', 'de', 'pl'],
  resources: {
    en: {
      translation: en,
    },
  },
  interpolation: {
    prefix: '%{',
    suffix: '}',
  },
});

app.mount('#app');

if (!['dev', 'local'].includes(`${process.env.VUE_APP_ENV}`)) {
  let sentryInited = false;
  const captureError = async (error: any) => {
    const { logError, initSentry } = await import(
      /* webpackChunkName: "sentry" */ './utilities/sentry'
    );
    if (!sentryInited) {
      sentryInited = true;
      initSentry(router, app);
    }
    logError(error);
  };
  window.onerror = (message, url, line, column, error) => captureError(error);
  window.onunhandledrejection = (event: any) => captureError(event?.reason);
}
