import { render, createVNode } from 'vue';
import tippy from 'tippy.js';
import TooltipText from '@/components/tooltips/TooltipText.vue';
import isMobile from '@/utilities/isMobile';
import capitalizeFirstLetter from '@/utilities/capitalizeFirstLetter';

type DirectiveProps = {
  component: any;
  componentStyle: { [key: string]: any };
  componentClass: string[] | string;
  componentProps: { [key: string]: any };
  componentEvents: { [key: string]: Function };
  onShow: Function;
  onTrigger: Function;
  onUntrigger: Function;
  onHide: Function;
  tippyOptions: { [key: string]: any };
  disabled: boolean;
  tooltip: boolean;
  classOnShow: string;
  text: string;
  shouldStopPropagation: boolean;
  renderDelay: number;
  mobilePlacement: string; // this has to be one of the values from tippy.js https://atomiks.github.io/tippyjs/v6/all-props/#placement
};

type DirectiveBinding = {
  value: DirectiveProps;
  oldValue: DirectiveProps;
};

function mountContent(el: any, vnode: any, value: DirectiveProps) {
  const {
    componentStyle = {},
    componentProps,
    componentEvents = {},
    text,
    component,
    componentClass = '',
  } = value;
  const tooltipContent = text ? TooltipText : component;

  // map listeners
  const listeners: any = {
    onEsc: () => {
      el._tippyInstance?.hide?.();
    },
    'onClose-tippy': () => {
      el._tippyInstance?.hide?.();
    },
  };

  Object.keys(componentEvents).forEach((attr) => {
    // event listeners should be passed as onXxx
    if (attr.startsWith('on')) {
      listeners[attr] = componentEvents[attr];
    } else {
      // append on to the event name and camel case it
      listeners[`on${capitalizeFirstLetter(attr)}`] = componentEvents[attr];
    }
  });

  const vNode = createVNode(tooltipContent, {
    ref: 'tooltipContent',
    ...(text && { text }),
    ...componentProps,
    style: componentStyle,
    class: componentClass,
    ...listeners,
  });

  // @ts-ignore
  vNode.appContext = window.StVueApp?._context;

  if (el) {
    render(vNode, el);
  }
  el._tippyContentVm = vNode;

  return vNode;
}

const createTippyInstance = (
  el: any,
  value: DirectiveProps,
  vnode: any,
  binding: DirectiveBinding
) => {
  const {
    onShow,
    onHide,
    onTrigger,
    onUntrigger,
    tippyOptions = {},
    tooltip = true,
    classOnShow = '',
    renderDelay = 500,
    shouldStopPropagation = false,
  } = value;

  // try to preserve the horizontal placement on mobile (start, end)
  if (isMobile()) {
    // to override default behavior for mobile, use mobilePlacement
    if (value.mobilePlacement) {
      tippyOptions.placement = value.mobilePlacement;
    } else {
      const horizPlacement = tippyOptions.placement?.split('-')[1];
      tippyOptions.placement = horizPlacement ? `auto-${horizPlacement}` : 'auto';
    }
  }

  const createTippyCallback = () => {
    const tippyInstance: any = tippy(el, {
      ...tippyOptions,
      role: tooltip ? 'tooltip' : 'poper',
      // @ts-ignore
      content: mountContent(el, vnode, value).el,
      ...(!tippyOptions.placement && { placement: 'top' }),
      ...(!tippyOptions.duration && { duration: [300, 0] }),
      ...(!tippyOptions.interactive && { interactive: false }),
      ...(!tippyOptions.maxWidth && { maxWidth: 'none' }),
      onTrigger: (_, event: Event) => {
        if (shouldStopPropagation) {
          event.stopPropagation();
        }
        if (onTrigger) {
          onTrigger(event);
        }
      },
      onUntrigger: (_, event: Event) => {
        if (shouldStopPropagation) {
          event.stopPropagation();
        }
        if (onUntrigger) {
          onUntrigger(event);
        }
      },
      onShow: (instance) => {
        if (classOnShow) {
          el.classList.add(classOnShow);
        }

        // @ts-ignore
        binding.instance.activeTippyInstance = instance;

        if (onShow && typeof onShow === 'function') {
          return onShow(instance, el._tippyContentVm);
        }
        if (tooltip) {
          instance.popper.classList.add('tooltip', 'popover', 'black-tooltip');
        }

        // ass vnode.context can have multiple tippy instances
        // we need to attach flag to element to avoid it being overwrite in update hook
        //! this currently abuses fact that we can only bind a single tippy instance to HTML element
        el._forceUpdate = true;
        // trigger a re-render of the component to make sure it will contain the newest data
        // @ts-ignore
        binding.instance._?.ctx?.$forceUpdate?.();
      },
      onHide: (instance: any) => {
        // @ts-ignore
        if (instance.id === binding.instance.activeTippyInstance?.id) {
          // @ts-ignore
          binding.instance.activeTippyInstance = null;
        }
        if (onHide && typeof onHide === 'function') {
          onHide(el._tippyContentVm);
        }
        if (classOnShow) {
          el.classList?.remove?.(classOnShow);
        }
      },
    });

    el._tippyInstance = tippyInstance;
  };

  if (renderDelay && !tooltip) {
    el._renderTippyTimeout = setTimeout(createTippyCallback, renderDelay);
    return;
  }

  createTippyCallback();
};

const tooltipDirective: any = {
  beforeMount(el: any, binding: DirectiveBinding, vnode: any) {
    const { value } = binding;
    const { disabled = false } = value;
    if (disabled) return;

    createTippyInstance(el, value, vnode, binding);
  },
  beforeUpdate(el: any, binding: DirectiveBinding, vnode: any) {
    const { value, oldValue } = binding;
    const { componentProps, disabled } = value;
    const { disabled: oldDisabled } = oldValue;

    if (el?._forceUpdate && el?._tippyInstance && el?._tippyContentVm) {
      el._forceUpdate = false;

      el._tippyContentVm.$el?.remove?.();
      el._tippyContentVm?.unmount?.();

      const newContentVm = mountContent(el, vnode, value);
      // @ts-ignore
      el._tippyInstance.setContent(newContentVm.$el);

      return;
    }

    if (!disabled && oldDisabled) {
      // create instance
      createTippyInstance(el, value, vnode, binding);
    }

    if (disabled && !oldDisabled) {
      // destroy instance
      el._tippyInstance?.destroy?.();
      el._tippyContentVm?.$destroy?.();
      return;
    }

    if (!componentProps || !el._tippyContentVm?.component?.props) return;
    Object.assign(el._tippyContentVm?.component?.props, componentProps);
    el._tippyContentVm.$forceUpdate?.(); // Force Vue to re-render the component
  },
  unmounted(el: any) {
    if (el._tippyInstance && !el._tippyInstance.state.isDestroyed) {
      el._tippyInstance.destroy();
    }
    if (el._tippyContentVm) {
      el._tippyContentVm.$destroy?.();
    }
    if (el._renderTippyTimeout) {
      clearTimeout(el._renderTippyTimeout);
    }
  },
};

export default tooltipDirective;
