import { getCurrentInstance, computed, Ref } from 'vue';
import useSetAndOpenProject from '@/utilities/composables/useSetAndOpenProject';
import { View, Project, Card, Page, Board, List } from '@superthread-com/api';
import { IEpic } from '@/types/epics';
import { ResourceType } from '@/types/resources';
import EventBus from '@/utilities/eventBus';

export default function useDeleteResource(id: Ref<string>, type: Ref<string>) {
  const _this = getCurrentInstance()?.proxy as any;
  if (!_this) return;

  const { setAndOpenProject, setAndOpenAdjacentProject } = useSetAndOpenProject() as any;

  let frozenTitle = '' as string;

  // delete functions

  const deleteProject = async () => {
    const currentProjId = _this.$store.getters.getCurrentProjectId;
    const deletingCurrentProject = currentProjId === id.value;

    if (deletingCurrentProject) {
      await setAndOpenAdjacentProject(id.value);
    }

    return _this.$store.dispatch('deleteProject', id.value).catch((err: Error) => {
      if (deletingCurrentProject) {
        setAndOpenProject(id.value);
      }
      throw err;
    });
  };

  const deleteCard = async () => {
    const result = await _this.$store.dispatch('deleteCard', {
      cardId: id.value,
      cardType: type.value,
    });

    if (_this.$store.getters.getIsCardDisplayed(id.value)) {
      EventBus.$emit('closeCard', {
        cardId: id.value,
      });
    }
    return result;
  };

  const deleteView = async () => {
    // when on view route and deleting the view, redirect to views route
    // possibly navigate back if it fails
    const deletingCurrentView = _this.$route.name === _this.$constants.routeNames.view;
    if (deletingCurrentView) {
      _this.$router.push({
        name: _this.$constants.routeNames.views,
        params: { dontFetch: true }, // dont fetch views on page load to avoid getting deleted view
      });
    }

    return _this.$store
      .dispatch('deleteView', {
        viewId: id.value,
        shared: (resource.value as View).shared,
      })
      .catch((err: Error) => {
        if (deletingCurrentView) {
          _this.$router.push({
            name: _this.$constants.routeNames.view,
            params: { viewId: id.value },
          });
        }
        throw err;
      });
  };

  const deletePage = async () => {
    const pageResource = resource.value as Page;
    const pageFromTree = _this.$store.getters.getProjectPagesTree(pageResource.project_id)[
      id.value
    ];

    const result = await _this.$store.dispatch('deletePage', {
      pageId: id.value,
      projectId: pageResource.project_id,
    });

    const allChildren: any[] = [];

    // collect all levels of child pages and remove them from recently viewed
    const collectAllChildren = (page: any) => {
      if (page.children) {
        page.children.forEach((childId: string) => {
          allChildren.push(childId);
          _this.$store.dispatch('removeFromElectronRecentlyViewed', {
            currentlyViewed: { id: childId, type: ResourceType.Page },
            teamId: _this.$store.getters.getTeamID,
          });
          const childPage = _this.$store.getters.getProjectPagesTree(pageResource.project_id)[
            childId
          ];
          if (childPage) {
            collectAllChildren(childPage);
          }
        });
      }
    };

    collectAllChildren(pageFromTree);

    const isChildPageOpened = allChildren.includes(currentPageId.value);

    if (openedPage.value.id === id.value || isChildPageOpened) {
      _this.$router.push(`/${teamSlug.value}/space-${currentProjectId.value}/boards`);
    }
    return result;
  };

  const deleteBoard = async () => {
    const result = await _this.$store.dispatch('deleteBoard', {
      boardId: id.value,
      projectId: (resource.value as Board).project_id,
    });

    if (currentBoardId === id.value) {
      _this.$router.push(`/${teamSlug.value}/space-${currentProjectId.value}/boards`);
    }

    return result;
  };

  const deleteList = () => {
    return _this.$store.dispatch('deleteList', {
      listId: id.value,
    });
  };

  const deleteResource = (): Promise<any> => {
    let deleteFn: Function;
    switch (type.value) {
      case ResourceType.Project:
        deleteFn = deleteProject;
        break;
      case ResourceType.View:
        deleteFn = deleteView;
        break;
      case ResourceType.Epic:
      case ResourceType.Card:
        deleteFn = deleteCard;
        break;
      case ResourceType.Board:
        deleteFn = deleteBoard;
        break;
      case ResourceType.Page:
        deleteFn = deletePage;
        break;
      case ResourceType.List:
        deleteFn = deleteList;
        break;
      default:
        deleteFn = () => Promise.reject(new Error('Invalid resource type'));
    }

    // we need to store the title before the resource gets deleted locally
    frozenTitle = resourceTitle.value;

    return deleteFn();
  };

  // resource getters

  const getProject = computed((): Project => {
    return _this.$store.getters.getProjectById(id.value);
  });

  const getView = computed((): View => {
    return (
      [..._this.$store.getters.getPrivateViews, ..._this.$store.getters.getPublicViews].find(
        (v: View) => v.id === id.value
      ) ?? {}
    );
  });

  const getCard = computed((): IEpic => {
    return _this.$store.getters.getCards[id.value] ?? {};
  });

  const getPage = computed((): Page => {
    return _this.$store.getters.getPageById(id.value) ?? {};
  });

  const getBoard = computed((): Board => {
    return _this.$store.getters.getFlatBoards[id.value] ?? {};
  });

  const getList = computed((): List => {
    return _this.$store.getters.lists[id.value] ?? {};
  });

  const resource = computed((): Project | View | IEpic | Card | Page | Board | List => {
    let res;

    switch (type.value) {
      case ResourceType.Project:
        res = getProject.value;
        break;
      case ResourceType.View:
        res = getView.value;
        break;
      case ResourceType.Epic:
      case ResourceType.Card:
        res = getCard.value;
        break;
      case ResourceType.Board:
        res = getBoard.value;
        break;
      case ResourceType.Page:
        res = getPage.value;
        break;
      case ResourceType.List:
        res = getList.value;
        break;
      default:
        res = {};
    }

    return res;
  });
  const resourceTitle = computed((): string => {
    let resTitle;
    if ('title' in resource.value) {
      resTitle = resource.value.title;
    } else if ('name' in resource.value) {
      resTitle = resource.value.name;
    }

    if (resTitle === '' && ResourceType.Page) return 'Untitled page';

    return resTitle || '';
  });

  // helpers

  const teamSlug = computed(() => _this.$store.getters.getTeamSlug);
  const currentProjectId = computed(() => _this.$store.getters.getCurrentProjectId);
  const currentBoardId = _this.$store.getters.currentBoardId;
  const currentPageId = computed(() => _this.$store.getters.getCurrentPageID);
  const openedPage = computed(() => _this.$store.getters.getPage);

  return {
    resourceTitle: computed(() => resourceTitle.value || frozenTitle),
    deleteResource,
  };
}
