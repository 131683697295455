import { BoardState } from '@/types';
import { nanoid } from 'nanoid';
import { browserStorageGetItem } from '@/utilities/browser-storage.util';
import { localBoardLayoutKey } from '@/utilities/localDrafts';
import { BoardLayout } from '@superthread-com/api';
import constants from '@/utilities/constants';
import isMobile from '@/utilities/isMobile';

const getDefaultBoardState = (): BoardState => ({
  lists: {},
  boardsListsIds: {},
  projectBoards: {},
  flatBoards: {},
  currentBoardId: '',
  newBoardProjectId: '',
  draftCard: {},
  listDraftCardCreateView: null,
  listsDraftCard: {},
  draggedEntity: {},
  dropTargetId: '',
  expandedBoardIds: {},
  loadingBoards: false,
  boardViewNoDestroy: false,
  draftListInputVisibleOnOpen: false,
  boardListView: 'list',
  currentTagForEdit: {},
  cardSidebarExpanded: {},
  showImageUploadPopup: false,
  currentCardIdActionEdit: '',
  currentListIdActionEdit: '',
  cardsLayout:
    browserStorageGetItem(localBoardLayoutKey(browserStorageGetItem('currentTeamId') || '')) || {},
  cardsLayoutOptions: [
    {
      value: BoardLayout.Board,
      label: 'cardsLayoutBoard',
      icon: 'board',
      default: true,
    },
    {
      value: BoardLayout.List,
      label: 'cardsLayoutList',
      icon: 'list',
    },
    {
      value: BoardLayout.Timeline,
      label: 'cardsLayoutTimeline',
      icon: 'timeline',
    },
    {
      value: BoardLayout.Calendar,
      label: 'cardsLayoutCalendar',
      icon: 'calendar',
    },
  ],
  removedListMeta: { position: '', boardId: '', listId: '' },
  removedCardMeta: {
    position: '',
    boardId: '',
    cardId: '',
    listId: '',
  },
  attachments: {},
  cardFailedAttachments: [],
  cardAutoFocus: false,
  previewPopoverKey: false,
  attachmentTargetCardId: -1,
  cardAttachmentsLoaded: false,
  archivedBoards: [],
  archivedLists: [],
  archivedCards: [],
  newBoardTitle: '',
  currentCardIdMemberDropdown: '',
  currentCardMembersDropdown: {},
  currentCardIdForPopup: '',
  projectPreviewBoards: {},
  displayProjectPreviewBoards: false,
  previewProject: {},
  cardAttachmentUploadingId: -1,
  showDuplicateCardPopup: '',
  showSetParentCardPopup: '',
  checklistTitle: '',
  draggingEnd: false,
  cards: {},
  listCards: {},
  currentCardId: '',
  displayedCards: [],
  currentCardLayout: isMobile()
    ? constants.modal
    : (localStorage.getItem('cardLayout') ?? constants.modal),
  linkedCardType: '',
  collapsedChecklistIds: [],
  hiddenItemsChecklistIds: [],
  boardsFilters: localStorage.getItem(`boardsFilters-${localStorage.getItem('currentTeamId')}`)
    ? JSON.parse(
        localStorage.getItem(`boardsFilters-${localStorage.getItem('currentTeamId')}`) ?? ''
      )
    : {},
  boardsPills: {},
  tags: {},
  projectTags: {},
  boardViewMouseSuspended: false,
  workspaceTags: {},
  cardCommentAttachmentUploadPopup: {},
  cardDescriptionAttachmentUploadPopup: {},
  lastOpenedBoardId: '',
  lastHoveredListId: '',
  lastHoveredCardId: '',
  lastHoveredDisplayedCardId: '',
  selectedCardId: '',
  checklistInputActive: {},
  checklistItemInputActive: {},
  reportInfoData: {},
  timelineZoom: 'quarter',
  timelineSidebarWidth: 350,
  cardTemplates: {},
  cardTemplateDraft: {
    title: '',
    icon: {},
    project_ids: [],
    card_title: '',
    card_content: '',
    card_board_id: '',
    card_list_id: '',
    card_members: [],
    card_tags: [],
    card_priority: 0,
    card_child_cards: [],
  },
  cardsSchema: 0,
  quickCardFilesToUpdate: [],
  cardIdForMove: '',
  quickCardObject: {
    localId: nanoid(6),
    title: '',
    content: '',
    tags: [],
    attachments: [],
    members: [],
    parentCard: {},
    projectId: '',
    priority: 0,
    board: {},
    listId: '',
    hints: [],
    childCards: [],
  },
  quickChildCardFilesToUpdate: [],
  quickChildCardObject: {
    localId: nanoid(6),
    title: '',
    content: '',
    tags: [],
    attachments: [],
    members: [],
    parentCard: {},
    projectId: '',
    priority: 0,
    board: {},
    listId: '',
    hints: [],
    childCards: [],
  },
  childCardCreation: false,
  cardInEpicCreation: '',
  cardInNoteCreation: '',
  boardSortByOptions: [
    {
      value: 'manual',
      label: 'manualOrder',
      icon: 'cursor',
      default: true,
    },
    {
      value: 'time_created',
      label: 'lastCreated',
      icon: 'calendar-created',
      viewDefault: true,
      inEpicBoardDefault: true,
    },
    {
      value: 'due_date',
      label: 'earliestDueDate',
      icon: 'calendar',
    },
    {
      value: 'time_updated',
      label: 'lastUpdated',
      icon: 'edit-outline',
    },
    {
      value: 'priority',
      label: 'highestPriority',
      icon: 'chevrons-up',
    },
    {
      value: 'estimate',
      label: 'largestEstimate',
      icon: 'clock',
    },
    {
      value: 'status',
      label: 'status',
      icon: 'play-circle',
    },
  ],
  boardSortBy: localStorage.getItem(`boardSortBy-${localStorage.getItem('currentTeamId')}`)
    ? JSON.parse(localStorage.getItem(`boardSortBy-${localStorage.getItem('currentTeamId')}`) ?? '')
    : {},
  priorityDropdown: false,
  showCardPriorityPopup: false,

  boardGroupByOptions: [
    {
      value: 'status',
      label: 'status',
      icon: 'play-circle',
      default: true,
    },
    {
      value: 'statusType',
      label: 'statusType',
      icon: 'play-circle',
      inEpicBoardDefault: true,
    },
    {
      value: 'assignee',
      label: 'assignee',
      icon: 'user',
    },
    {
      value: 'tag',
      label: 'tag',
      icon: 'tag',
    },
    {
      value: 'priority',
      label: 'priority',
      icon: 'chevrons-up',
    },
    {
      value: 'space',
      label: 'space',
      icon: 'folder',
    },
    {
      value: 'none',
      label: 'none',
      icon: 'x',
    },
    {
      value: 'epic',
      label: 'epic',
      icon: 'map',
    },
  ],
  boardGroupBy: localStorage.getItem(`boardGroupBy-${localStorage.getItem('currentTeamId')}`)
    ? JSON.parse(
        localStorage.getItem(`boardGroupBy-${localStorage.getItem('currentTeamId')}`) ?? ''
      )
    : {},
  boardListGroupByOrder: localStorage.getItem(
    `groupByOrder-${localStorage.getItem('currentTeamId')}`
  )
    ? JSON.parse(
        localStorage.getItem(`groupByOrder-${localStorage.getItem('currentTeamId')}`) ?? ''
      )
    : {},
  listCardsGroupByOrder: localStorage.getItem(
    `listCardsGroupByOrder-${localStorage.getItem('currentTeamId')}`
  )
    ? JSON.parse(
        localStorage.getItem(`listCardsGroupByOrder-${localStorage.getItem('currentTeamId')}`) ?? ''
      )
    : {},
  cardWidth: 600,
  showSuggestionsPlaceholder: false,
  qcShowSuggestionsPlaceholder: false,
  currentGroupByListIdCardActionEdit: '',
  currentCardUniqueRowId: '',
  newBoardObject: {},
  sprintSettings: {},
  spaceSprints: {},
  sprintLists: {},
  sprintForEditId: {},
  epicSelectedTabId: {},
});

export default getDefaultBoardState;
