import { createStore } from 'vuex';

import getDefaultState from './state';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import modules from './modules';

export const store = createStore({
  strict: false,
  state: getDefaultState(),
  actions,
  mutations,
  getters,
  modules,
});
