<template>
  <button
    ref="button"
    :class="[
      'button-wrap usn',
      type,
      variation,
      disabled ? 'disabled pen' : '',
      { 'right-spacing': rightSpacing },
      { 'left-spacing': leftSpacing },
    ]"
    :style="buttonDimensionsStyle"
    @mousedown="mousedownHandler"
    @click="clickHandler"
  >
    <slot>
      <span
        v-if="labelLeft && !noLabel"
        class="button-label"
        :class="{ 'animated-dots': animateThreeDot }"
      >
        {{ text }}
      </span>
      <em
        v-if="customIconName"
        class="button-icon custom-icons custom"
        :class="{ 'bg-icon-default group-hover:bg-icon-hover': themeStyle }"
        :style="customIconStyle"
      />
      <span
        v-if="!labelLeft && !noLabel"
        class="button-label"
        :class="{ 'animated-dots': animateThreeDot }"
      >
        {{ text }}
      </span>
    </slot>
  </button>
</template>

<script>
import useCustomIcon from '@/utilities/composables/useCustomIcon';

export default {
  name: 'ButtonCustom',
  props: {
    text: {
      type: String,
      required: false,
      default: '',
    },
    type: {
      type: String,
      required: false,
      default: '',
    },
    leftSpacing: {
      type: Boolean,
      required: false,
      default: false,
    },
    rightSpacing: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    variation: {
      type: String,
      required: false,
      default: '',
    },
    buttonDimensions: {
      type: Object,
      required: false,
      default: () => ({}),
    },

    customIconName: {
      type: String,
      required: false,
      default: '',
    },

    customIconColor: {
      type: String,
      required: false,
      default: 'var(--iconDefault)',
    },

    labelLeft: {
      type: Boolean,
      required: false,
      default: false,
    },

    noLabel: {
      type: Boolean,
      required: false,
      default: false,
    },

    customIconSize: {
      type: String,
      required: false,
      default: '20px',
    },

    animateThreeDot: {
      type: Boolean,
      required: false,
      default: false,
    },
    themeStyle: {
      type: Boolean,
      required: false,
      default: false,
    },
    customIconProps: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  emits: ['mousedown', 'click'],
  setup() {
    const { iconPath, getCustomIconStyle } = useCustomIcon();

    return {
      iconPath,
      getCustomIconStyle,
    };
  },
  computed: {
    buttonDimensionsStyle() {
      if (Object.keys(this.buttonDimensions).length) {
        const { height, width } = this.buttonDimensions;
        return {
          width: `${width}${typeof width === 'number' ? 'px' : ''}`,
          height: `${height}${typeof height === 'number' ? 'px' : ''}`,
        };
      }
      return {};
    },

    customIconStyle() {
      return this.getCustomIconStyle(this.iconPath(this.customIconName), {
        backgroundColor: `${this.customIconColor}`,
        width: this.customIconSize,
        height: this.customIconSize,
        ...this.customIconProps,
      });
    },
  },
  methods: {
    mousedownHandler($event) {
      if (this.disabled) return;
      this.$emit('mousedown', $event);
    },
    clickHandler($event) {
      if (this.disabled) return;
      this.$emit('click', $event);
    },
  },
};
</script>
